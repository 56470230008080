import React, { useState } from "react";
import "./myteam.scss";
import Header from "../landing/navbar/Header";
import { Nav } from "react-bootstrap";
import Datatable from "../AllTransaction/DataTable/Datatable";
import Footerline from "../landing/footer/Footerline";
import { Link } from "react-router-dom";
const Teamdetail = () => {
  const [activeTab, setActiveTab] = useState("link-1");

  const handleSelect = (eventKey) => {
    setActiveTab(eventKey);
  };
  return (
    <>
      <Header />
      <section className="teamdetail">
        <div className="theme-container">
          <div className="teamdetailtop">
            <div className="teamdetailleft">
              <div className="teamdetailimg">
                <img
                  src="\assets\dashboardassets\teamimg.svg"
                  alt="innerteamimg"
                  className="innerteamimg"
                />
              </div>
              <div className="teamdetailtexts">
                <h3>Clayton Bowers</h3>
                <p>
                  <span>God Leader</span> <span className="forline"></span>
                  1644978451315487
                </p>
              </div>
            </div>
            <div className="teamdetailright">
              <Link to="/newleaderprofile">
                <button>View Profile</button>
              </Link>
              <div className="first">
                <div class="toggle-switch">
                  <input class="toggle-input" id="toggle1" type="checkbox" />
                  <label class="toggle-label" for="toggle1"></label>
                </div>
                <p>Disable this Leader</p>
              </div>
            </div>
          </div>
          <div className="teamdetailcard">
            <div className="teamparent">
              <div className="innercontent">
                <h3>Created On</h3>
                <p>30/06/2023 08:47 UTC</p>
              </div>
              <div className="innercontent">
                <h3>Owner’s Wallet Address</h3>
                <p>0x60E46C0E8572E89A...4B2A</p>
              </div>
              <div className="innercontent">
                <h3>Email address</h3>
                <p>claytonbowers@gmail.com</p>
              </div>
              <div className="innercontent">
                <h3>Sale Commission</h3>
                <p>15%</p>
              </div>
            </div>
          </div>
          <div className="dashboardboxesmain">
            <div className="innerdashboardbox">
              <div className="innerdashboardboxupper">
                <img
                  src="\assets\dashboardassets\cardtwo.svg"
                  alt="dashboardboximg"
                  className="dashboardboximg"
                />
                <div className="dashboardboxtexts">
                  <p className="dashboardparatopbox">No. of Purchases</p>
                  <h6 className="dashboardheadbox">
                    48.8K
                    <span className="greentext">+2.3%</span>
                  </h6>
                </div>
              </div>
            </div>
            <div className="innerdashboardbox">
              <div className="innerdashboardboxupper">
                <img
                  src="\assets\dashboardassets\cardone.svg"
                  alt="dashboardboximg"
                  className="dashboardboximg"
                />
                <div className="dashboardboxtexts">
                  <p className="dashboardparatopbox">Total Purchases</p>
                  <h6 className="dashboardheadbox">
                    ~$23K
                    <span className="redtext">-0.6%</span>
                  </h6>
                </div>
              </div>
              <div className="valuemaindiv">
                <div className="valuemain">
                  <span className="valueline"></span>
                  <p className="valuepara">1.3 ETH</p>
                </div>
                <div className="valuemain">
                  <span className="valueline"></span>
                  <p className="valuepara">2.3 USDT</p>
                </div>
              </div>
            </div>
            <div className="innerdashboardbox">
              <div className="innerdashboardboxupper">
                <img
                  src="\assets\dashboardassets\newcard.svg"
                  alt="dashboardboximg"
                  className="dashboardboximg"
                />
                <div className="dashboardboxtexts">
                  <p className="dashboardparatopbox">Leader Earning</p>
                  <h6 className="dashboardheadbox">
                    ~$1,000
                    <span className="greentext">+0.6%</span>
                  </h6>
                </div>
              </div>
              <div className="valuemaindiv">
                <div className="valuemain">
                  <span className="valueline"></span>
                  <p className="valuepara">1.3 ETH</p>
                </div>
                <div className="valuemain">
                  <span className="valueline"></span>
                  <p className="valuepara">2.3 USDT</p>
                </div>
              </div>
            </div>
          </div>
          <div className="tabsmain">
            <Nav variant="pills" activeKey={activeTab} onSelect={handleSelect}>
              <Nav.Item>
                <Nav.Link eventKey="link-1">All Transactions</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="link-2">Projects</Nav.Link>
              </Nav.Item>
            </Nav>
            <div className="searchinputmain">
              <input
                type="text"
                placeholder="Search..."
                className="searchinput"
              />
              <img
                src="\assets\dashboardassets\search-normal.svg"
                alt="searchinputimg"
                className="searchinputimg"
              />
            </div>
          </div>

          {activeTab === "link-1" && (
            <>
              <Datatable />
            </>
          )}

          {activeTab === "link-2" && (
            <>
              <section className="mainproject">
                <div className="main-card">
                  <div className="table-responsive">
                    <table className="table">
                      <thead className="tblheadss">
                        <tr>
                          <th>
                            Project{" "}
                            <img
                              src="\Group120.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </th>
                          <th>
                            Code{" "}
                            <img
                              src="\Group120.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </th>
                          <th>
                            No. of purchases{" "}
                            <img
                              src="\Group120.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </th>
                          <th>
                            Leader Earnings{" "}
                            <img
                              src="\Group120.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </th>
                          <th>
                            commision %{" "}
                            <img
                              src="\Group120.svg"
                              alt="img"
                              className="img-fluid"
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <div className="parenttbl">
                              <div className="left">
                                <img
                                  src="\assets\dashboardassets\tokn.svg"
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="right">
                                <h6>TOKN</h6>
                                <p>TokenXYZ</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="para">
                              635984{" "}
                              <img
                                src="\assets\dashboardassets\Copy.svg"
                                alt="img"
                                className="copy"
                              />
                            </p>
                          </td>
                          <td>
                            {" "}
                            <p className="para">~$23K</p>
                          </td>
                          <td>
                            <h6>~$2,112</h6>
                            <p>(1.5 ETH + 3.3 USDT)</p>
                          </td>
                          <td>
                            {" "}
                            <p className="para">2.5%</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="parenttbl">
                              <div className="left">
                                <img
                                  src="\assets\dashboardassets\tokn.svg"
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="right">
                                <h6>TOKN</h6>
                                <p>TokenXYZ</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="para">
                              635984{" "}
                              <img
                                src="\assets\dashboardassets\Copy.svg"
                                alt="img"
                                className="copy"
                              />
                            </p>
                          </td>
                          <td>
                            {" "}
                            <p className="para">~$23K</p>
                          </td>
                          <td>
                            <h6>~$2,112</h6>
                            <p>(1.5 ETH + 3.3 USDT)</p>
                          </td>
                          <td>
                            {" "}
                            <p className="para">2.5%</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="parenttbl">
                              <div className="left">
                                <img
                                  src="\assets\dashboardassets\tokn.svg"
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="right">
                                <h6>TOKN</h6>
                                <p>TokenXYZ</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="para">
                              635984{" "}
                              <img
                                src="\assets\dashboardassets\Copy.svg"
                                alt="img"
                                className="copy"
                              />
                            </p>
                          </td>
                          <td>
                            {" "}
                            <p className="para">~$23K</p>
                          </td>
                          <td>
                            <h6>~$2,112</h6>
                            <p>(1.5 ETH + 3.3 USDT)</p>
                          </td>
                          <td>
                            {" "}
                            <p className="para">2.5%</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="parenttbl">
                              <div className="left">
                                <img
                                  src="\assets\dashboardassets\tokn.svg"
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="right">
                                <h6>TOKN</h6>
                                <p>TokenXYZ</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="para">
                              635984{" "}
                              <img
                                src="\assets\dashboardassets\Copy.svg"
                                alt="img"
                                className="copy"
                              />
                            </p>
                          </td>
                          <td>
                            {" "}
                            <p className="para">~$23K</p>
                          </td>
                          <td>
                            <h6>~$2,112</h6>
                            <p>(1.5 ETH + 3.3 USDT)</p>
                          </td>
                          <td>
                            {" "}
                            <p className="para">2.5%</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="parenttbl">
                              <div className="left">
                                <img
                                  src="\assets\dashboardassets\tokn.svg"
                                  alt="img"
                                  className="img-fluid"
                                />
                              </div>
                              <div className="right">
                                <h6>TOKN</h6>
                                <p>TokenXYZ</p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p className="para">
                              635984{" "}
                              <img
                                src="\assets\dashboardassets\Copy.svg"
                                alt="img"
                                className="copy"
                              />
                            </p>
                          </td>
                          <td>
                            {" "}
                            <p className="para">~$23K</p>
                          </td>
                          <td>
                            <h6>~$2,112</h6>
                            <p>(1.5 ETH + 3.3 USDT)</p>
                          </td>
                          <td>
                            {" "}
                            <p className="para">2.5%</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="footer-content">
                    <div className="left-f">
                      <h6>SHOWING 1-10 OF 145</h6>
                    </div>
                    <div className="right-f">
                      <nav aria-label="Page navigation example">
                        <ul class="pagination">
                          <li class="page-item">
                            <a class="page-link" href="#">
                              {"<"}
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link active" href="#">
                              1
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              2
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              3
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              4
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              5
                            </a>
                          </li>
                          <li class="page-item">
                            <a class="page-link" href="#">
                              {">"}
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
      </section>

      <Footerline />
    </>
  );
};

export default Teamdetail;
