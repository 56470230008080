import React, { useState } from "react";
import "./myteam.scss";
import Header from "../landing/navbar/Header";
import { Dropdown, Modal } from "react-bootstrap";
import Datatable from "./DataTable/Datatable";
import Footerline from "../landing/footer/Footerline";
import { Link, useHistory } from "react-router-dom";

const Myteam = () => {
  const history = useHistory();
  const tok = localStorage.getItem("accessToken");
  const user = JSON.parse(localStorage.getItem("user"));

  const [toggle, settoggle] = useState(true);
  return (
    <>
      <Header />

      <section className="myteam">
        <div className="theme-container">
          <div className="parent">
            <div className="left">
              <h2>My Team</h2>
            </div>
            <div className="right">
              <div className="innerright">
                <input
                  type="text"
                  placeholder="Search..."
                  className="paddiinput"
                />
                <img
                  src="\assets\dashboardassets\search-normal.svg"
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <div className="dropbtn">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    No. Of Purchases
                    <img
                      src="\assets\dashboardassets\arrowdropdown.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          {toggle ? (
            <div className="addteammain">
              <img
                src="\assets\myteam\user-cirlce-add.svg"
                alt="addteamimg"
                className="addteamimg"
              />
              <h6 className="addteamhead">Add a team member</h6>
              <p className="addteampara">
                Sed omnis repellendus id vitae rerum et amet veritatis
              </p>
              <button className="addteambtn">Add Team Member</button>
            </div>
          ) : (
            <Datatable />
          )}
        </div>
      </section>
      <Footerline />
    </>
  );
};

export default Myteam;
