import React, { useEffect } from "react";
import "./banner.scss";
import { Link, useHistory } from "react-router-dom";

const Banner = () => {
  const history = useHistory();
  const tok = localStorage.getItem("accessToken");
  useEffect(() => {
    window.scrollTo(0, 0);
    if (tok && tok !== null && tok !== undefined) {
      history.push("/dashboard");
    } else {
      history.push("/login");
    }
  }, []);
  return (
    <>
      <section className="main-banner">
        {/* <div className="innerbannercard">
          <img
            src="\assets\bannerassets\cardone.svg"
            alt="innercardimg"
            className="innercardimg"
          />
          <h6 className="innercardhead">Become a Creator</h6>
          <p className="innercardpara">
            Qui repellat dolores sed repudiandae laboriosam vel modi dolore quo
            ipsum quibusdam qui
          </p>
          <button className="dullbtn w-100">Coming Soon</button>
          <p className="dulltext">
            Already have an account? <span className="dullbold">Log In</span>
          </p>
        </div>
        <div className="innerbannercard">
          <img
            src="\assets\bannerassets\cardtwo.svg"
            alt="innercardimg"
            className="innercardimg"
          />
          <h6 className="innercardhead">Become a Leader</h6>
          <p className="innercardpara">
            Qui repellat dolores sed repudiandae laboriosam vel modi dolore quo
            ipsum quibusdam qui
          </p>
          <Link to="/signup" className="w-100">
            <button className="blackbtn w-100">Sign Up as Leader</button>
          </Link>
          <p className="blacktext">
            Already have an account?
            <Link to="/login">
              <span className="blackbold"> Log In</span>
            </Link>
          </p>
        </div> */}
      </section>
    </>
  );
};

export default Banner;
