import React from "react";
import "./leaderprofile.scss";
import Header from "../landing/navbar/Header";
import Footerline from "../landing/footer/Footerline";
import { Dropdown } from "react-bootstrap";

const Newleaderprofile = () => {
  return (
    <>
      <Header />
      <section className="leaderprofile">
        <div className="theme-container">
          <div className="mainleaderprofile">
            <div className="leaderprofileleft">
              <div className="leaderprofileleftup">
                <div className="leaderprofileleftmainimg">
                  <img
                    src="\assets\dashboardassets\image.png"
                    alt="leaderprofileleftinnerimg"
                    className="leaderprofileleftinnerimg"
                  />
                </div>
                <h6 className="leaderprofilehead">Clayton Bowers</h6>
                <p className="leaderprofilepara">Ambassador</p>
                <span className="activespan">Active</span>
                <div className="sendinvitebtnmain">
                  <button className="whitebtn w-100">Send Invite</button>
                </div>
                <div className="leaderprofiledetail">
                  <div className="leaderprofiledetailinner">
                    <p className="leaderprofileparainner">Joined On</p>
                    <h6 className="leaderprofileheadinner">30/06/2023</h6>
                  </div>
                  <div className="leaderprofiledetailinner">
                    <p className="leaderprofileparainner">Wallet Address</p>
                    <h6 className="leaderprofileheadinner">
                      0x60E46C0E...4B2A
                    </h6>
                  </div>
                  <div className="leaderprofiledetailinner">
                    <p className="leaderprofileparainner">Email address</p>
                    <h6 className="leaderprofileheadinner">
                      claytonb@gmail.com
                    </h6>
                  </div>
                </div>
              </div>
              <div className="leaderprofileleftlow">
                <div className="leaderprofileleftlowdetail">
                  <p className="leaderprofileleftlowdetailpara">Projects</p>
                  <h6 className="leaderprofileleftlowdetailhead">85</h6>
                </div>
                <div className="leaderprofileleftlowdetail">
                  <p className="leaderprofileleftlowdetailpara">Total Sale</p>
                  <h6 className="leaderprofileleftlowdetailhead">10.2 K</h6>
                </div>
                <div className="leaderprofileleftlowdetail">
                  <p className="leaderprofileleftlowdetailpara">Earning</p>
                  <h6 className="leaderprofileleftlowdetailhead">$4.1 K</h6>
                </div>
              </div>
            </div>
            <div className="bio">
              <div className="maincard">
                <div className="main-head">
                  <h2>Bio</h2>
                  <p>
                    Aut iste itaque qui rerum iusto ad omnis reiciendis id
                    laborum galisum qui iste quibusdam et quos voluptas. Sed
                    magni nesciunt eum voluptatem dignissimos id quisquam nulla
                    aut officia quaerat. Non accusamus deleniti ut enim
                    asperiores qui ratione quia aut nisi natus quo voluptate
                    explicabo.
                  </p>
                  <p>
                    Sit voluptatem dolores aut corporis quia qui soluta
                    temporibus non numquam quis cum ratione dolores ut molestiae
                    possimus et officiis expedita. Est tenetur molestiae est
                    dolor veniam vel facere assumenda qui placeat dignissimos?
                    Eos debitis dolor et explicabo veritatis aut aperiam
                    corrupti sed voluptas sunt in cumque illum!
                  </p>
                  <p>
                    Lorem ipsum dolor sit amet. Et voluptas reprehenderit eum
                    ipsa nulla nam provident saepe in tempore illum. Qui
                    recusandae labore sit provident voluptas est doloremque
                    dicta ut dolorem pariatur aut ducimus perferendis rem facere
                    adipisci! Qui obcaecati minima et omnis neque id consequatur
                    ipsum et culpa rerum et possimus provident sit expedita
                    fugiat ut enim voluptate.
                  </p>
                  <h2 className="project">Past Projects</h2>
                </div>
                <div className="parentcard">
                  <div className="innercard">
                    <div className="innercontent">
                      <div className="left">
                        <img
                          src="\assets\dashboardassets\tokenimg.png"
                          alt="img"
                          className="img-fluid picc"
                        />
                      </div>
                      <div className="right">
                        <h3>TOKN</h3>
                        <p>TokenXYZ</p>
                      </div>
                    </div>
                    <div className="brdr"></div>
                    <div className="parentcardsss">
                      <h3>No. of Purchases</h3>
                      <p>23K </p>
                    </div>
                    <div className="parentcardsss">
                      <h3>Total Purchases</h3>
                      <p>$2.5K </p>
                    </div>
                  </div>
                  <div className="innercard">
                    <div className="innercontent">
                      <div className="left">
                        <img
                          src="\assets\dashboardassets\tokenimg.png"
                          alt="img"
                          className="img-fluid picc"
                        />
                      </div>
                      <div className="right">
                        <h3>TOKN</h3>
                        <p>TokenXYZ</p>
                      </div>
                    </div>
                    <div className="brdr"></div>
                    <div className="parentcardsss">
                      <h3>No. of Purchases</h3>
                      <p>23K </p>
                    </div>
                    <div className="parentcardsss">
                      <h3>Total Purchases</h3>
                      <p>$2.5K </p>
                    </div>
                  </div>
                  <div className="innercard">
                    <div className="innercontent">
                      <div className="left">
                        <img
                          src="\assets\dashboardassets\tokenimg.png"
                          alt="img"
                          className="img-fluid picc"
                        />
                      </div>
                      <div className="right">
                        <h3>TOKN</h3>
                        <p>TokenXYZ</p>
                      </div>
                    </div>
                    <div className="brdr"></div>
                    <div className="parentcardsss">
                      <h3>No. of Purchases</h3>
                      <p>23K </p>
                    </div>
                    <div className="parentcardsss">
                      <h3>Total Purchases</h3>
                      <p>$2.5K </p>
                    </div>
                  </div>
                  <div className="innercard">
                    <div className="innercontent">
                      <div className="left">
                        <img
                          src="\assets\dashboardassets\tokenimg.png"
                          alt="img"
                          className="img-fluid picc"
                        />
                      </div>
                      <div className="right">
                        <h3>TOKN</h3>
                        <p>TokenXYZ</p>
                      </div>
                    </div>
                    <div className="brdr"></div>
                    <div className="parentcardsss">
                      <h3>No. of Purchases</h3>
                      <p>23K </p>
                    </div>
                    <div className="parentcardsss">
                      <h3>Total Purchases</h3>
                      <p>$2.5K </p>
                    </div>
                  </div>
                </div>
                <div className="acheivmentmain">
                  <h6 className="acheivmentmainhead">Achievements</h6>
                  <div className="acheivmentmaincardsdiv">
                    <div className="acheivementcard">
                      <span className="shade"></span>
                      <div className="acheivementcardimg">
                        <img
                          src="\assets\dashboardassets\tokenimg.png"
                          alt="acheivementcardinnerimg"
                          className="acheivementcardinnerimg"
                        />
                      </div>
                      <h6 className="acheivementcardhead">TokenXYZ</h6>
                      <p className="acheivementcardparablue">
                        Sold 1,000,000 TOKN & Won
                      </p>
                      <h6 className="acheivementcardheadblue">5,000 USDT</h6>
                      <p className="acheivementcardpara">Reward Bonus</p>
                    </div>
                    <div className="acheivementcard">
                      <span className="shade lightblue"></span>
                      <div className="acheivementcardimg">
                        <img
                          src="\assets\dashboardassets\tokenimg.png"
                          alt="acheivementcardinnerimg"
                          className="acheivementcardinnerimg"
                        />
                      </div>
                      <h6 className="acheivementcardhead">TokenXYZ</h6>
                      <p className="acheivementcardparablue">
                        Sold 1,000,000 TOKN & Won
                      </p>
                      <h6 className="acheivementcardheadblue">10,000 USDT</h6>
                      <p className="acheivementcardpara">Reward Bonus</p>
                    </div>
                    <div className="acheivementcard">
                      <span className="shade golden"></span>
                      <div className="acheivementcardimg">
                        <img
                          src="\assets\dashboardassets\tokenimg.png"
                          alt="acheivementcardinnerimg"
                          className="acheivementcardinnerimg"
                        />
                      </div>
                      <h6 className="acheivementcardhead">TokenXYZ</h6>
                      <p className="acheivementcardparablue">
                        Sold 1,000,000 TOKN & Won
                      </p>
                      <h6 className="acheivementcardheadblue">1,000 USDT</h6>
                      <p className="acheivementcardpara">Reward Bonus</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footerline />
    </>
  );
};

export default Newleaderprofile;
