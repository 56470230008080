import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import Header from "../landing/navbar/Header";

const BannerEducation = ({ scrollToSecondSection }) => {
  

  // const [showNav, setShowNav] = useState(true);

 
  return (
    <>
      <Header />
      <section className="main-bannernew">
        <img
          src="\assets\education\banner-bg.png"
          alt="img"
          className="img-fluid banner-bg"
        />
        <div className="custom-container">
          <div className="main-contentss">
            <div className="faqsheads">
              <p className="green-text">Education</p>
              <h3>LEARN HOW TO USE THE GEMS PLATFORM</h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BannerEducation;
