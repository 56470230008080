import "../../App.scss";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Banner from "./main-banner/Banner.js";
import Footer from "./footer/Footer.js";
import Header from "./navbar/Header.js";

function Landing() {
  const history = useHistory();
  const tok = localStorage.getItem("accessToken");
  useEffect(() => {
    window.scrollTo(0, 0);
    if (tok && tok !== null && tok !== undefined) {
      history.push("/dashboard");
    } else {
      history.push("/login");
    }
  }, []);

  return (
    <>
      <Header />
      <Banner />
      <Footer />
    </>
  );
}

export default Landing;
