import { useCallback, useState } from "react";
import useWeb3 from "../useWeb3";
import {
  getdoproundcreatecontractold,
  getGemsPreSaleContract,
  getIncentivPreSaleContract,
} from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import Environment from "../../utils/Environment";

const TimerNFT = () => {
  const [balance, setBalance] = useState(null);
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = Environment.CreateDopRound;
  const contract = getdoproundcreatecontractold(tokenAddress, web3);

  const gemsAddress = Environment.GemsPreSale;
  const incentivAddress = Environment.IncentivPreSale;

  const gemsContractPresale = getGemsPreSaleContract(gemsAddress, web3);
  const incentivContractPresale = getIncentivPreSaleContract(
    incentivAddress,
    web3
  );
  const project = localStorage.getItem("project");

  const timerNFT = useCallback(
    async (e) => {
      if (project == "INCENTIV") {
        try {
          const buy = await incentivContractPresale.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      } else if (project == "GEMS") {
        try {
          const buy = await contract.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      } else {
        try {
          const buy = await contract.methods.rounds(e).call();
          return buy;
        } catch (error) {
          throw error;
        }
      }
    },
    [contract, account, web3]
  );
  return { timerNFT: timerNFT };
};
export default TimerNFT;
