let Environment = {
  TomiContract: "0x4385328cc4D643Ca98DfEA734360C0F596C83449",
  CreateDopRound: "0x2D4e8121AeE68679054018037832947a3E0Bcf8A",
  // CreateNFTRound: "0x5C5576b7e36270A78A35495B5843Fc1EBb4b5FfE", //test net

  CreateNFTRound: "0x1119c2d708E42Fb591FCe77283b0791a135855ba", //main net

  CreateDopTomiRound: "0xf4Fa4f08811Ea1F79a809129192279d97D88EF51", //test net
  // CreateNFTRound: "0x5C5576b7e36270A78A35495B5843Fc1EBb4b5FfE", //test net

  CreateNFTTomiRound: "0xCFd7cB1528f32aDFFC40D2A7199E80799E4E4736", //test net

  DOPRoundSingleContract: "0x2B316b6E4fFD1984a2dE9b33E42787923d77F390", // main net

  InstitutionPreSale: "0x2E1858809393FaDb72D406Bb4E4699AD759e88fa", // main net

  Claims: "0x08669B18fe3A661400ac8dddF837e90861BFf5B6", //this one is for rounds 1 and 2
  ClaimsNew: "0x1B7168cA64fCd77fDF713767e6cDe2d7757241AB", // rounds greater then 2
  oldGraph:
    "https://api.studio.thegraph.com/query/51954/dopgraphcombined/version/latest", // for round smaller than or equal to 6
  graph: "https://api.thegraph.com/subgraphs/name/saifdevblochain/presaledop", // for round greater then 6

  institutionGraph:
    "https://api.studio.thegraph.com/query/63987/institutionpresale-live/0.0.1",

  claimNewLatest: "0x2cb197409ae65b344a611E2ab99A0E864EF28d4c",
  busd: "0xdAC17F958D2ee523a2206206994597C13D831ec7", //MAINNET
  tomi: "0x4385328cc4D643Ca98DfEA734360C0F596C83449", //MAINNET
  eth: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE", //MAINNET
  usdc: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", //MAINNET
  wbtc: "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599", //MAINNET
  gems: "0x3010ccb5419F1EF26D40a7cd3F0d707a0fa127Dc", //MAINNET
  pepe: "0x6982508145454Ce325dDbE47a25d4ec3d2311933", //MAINNET
  link: "0x514910771AF9Ca656af840dff83E8264EcF986CA", //MAINNET
  uni: "0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984", //MAINNET
  dop: "0x97A9a15168C22B3C137E6381037E1499C8ad0978", //MAINNET
  /////// GEMS MAINNET

  GemsStakingGraph:
    "https://api.studio.thegraph.com/query/74755/staking-index/001", //MAINNET

  GemsGraph:
    "https://api.studio.thegraph.com/query/63987/gems-presale-main/0.4.1", //MAINNET
  GemsClaim: "0x96d7e90E6176466a0D882fc8BA6436021F1AeB06", //MAINNET
  GemsPreSale: "0x6236e15ab57bAA2d1465660Ef4Ec3B0A8D071090", //MAINNET
  GemsStaking: "0x6A1aa5ac35f38Bdb25BBD976E8eca942b23260b2", //MAINNET

  //// Incentiv

  IncentivGraph:
    "https://api.studio.thegraph.com/query/63987/incentive-main/v1.1.1.1", //MAINNET

  IncentivClaim: "0x27E00382b0C3eCd74d1505e8CD73706d79D0Bd54", //MAINNET
  IncentivPreSale: "0x20361bC401EA01e5d6E01596c445Af1a48e1171b", //MAINNET

  //// LEIA

  LEIAPreSale: "0x3aEdC9075A8238A68BEc28495ea566f9abcA2840", //MAINNET
  LEIAClaim: "0xB107140C5737Bfa6dBBe46db6b359Caf6e51524D", //MAINNET
  LEIAGraph: "https://api.studio.thegraph.com/query/63987/leia-main/v-1.1", //MAINNET
  LEIAInsurance: "0xEf9845956E5A68A28dCF04dAa1282acB85545e41", //MAINNET

  //// OLYMPUS

  OlympusPreSale: "0xaDF3202480bD62F3dB150E27A0F811f999F253B9", //MAINNET
  OlympusClaim: "0x1a11428b6F7903024B79aa7843d59895c817a3ad", //MAINNET
  OlympusGraph: "https://api.studio.thegraph.com/query/63987/olympus-main/v-1", //MAINNET
  OlympusInsurance: "0x18c147D13839cDBD33058c2E3B4c0531D16003C5", //MAINNET
};
export default Environment;
