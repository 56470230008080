import React, { useEffect, useState } from "react";
import BannerEducation from "./BannerEducation";
import "./education.scss"
import { Modal } from "react-bootstrap";

const Education = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    return (
        <>
            <div className="wrapper-apply wrapper-career">
                <BannerEducation />
                <section className="education-section">
                    <div className="custom-container">
                        <div className="main-heading">
                            <h6>Leader Education Videos</h6>
                        </div>
                        <div className="bottom-content">
                            <div className="single-card" onClick={() => {setShow1(true)}}>
                                <div className="main-img">
                                    <img src="\assets\education\becomingaleader.png" alt="img" className="img-fluid poster-video" />
                                    <img src="\assets\education\play-icon.svg" alt="img" className="img-fluid play-icon" />
                                </div>
                                <h6>Becoming a Premium Leader</h6>
                            </div>
                            <div className="single-card" onClick={() => {setShow1(true)}}>
                                <div className="main-img">
                                    <img src="\assets\education\addingnewleader.png" alt="img" className="img-fluid poster-video" />
                                    <img src="\assets\education\play-icon.svg" alt="img" className="img-fluid play-icon" />
                                </div>
                                <h6>Adding a new leader to your team</h6>
                            </div>
                        </div>
                    </div>
                </section>
            </div>


            <Modal className='image-preview' show={show1} onHide={handleClose1} centered>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className='p-0'>
                    <iframe width="100%" height="100%" src="https://www.youtube.com/embed/C9-Bj5PqEmA" title="Animals: The Colors of Nature On Display" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Education;
