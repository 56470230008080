import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import tableup from "../../../assets/tableup.svg";
import tabledown from "../../../assets/tabledown.svg";
import { Accordion, Dropdown } from "react-bootstrap";

const Datatable = () => {
  return (
    <>
      <section className="datatable">
        <div className="newtable">
          <div className="table-responsive d-block newtableinner">
            <table class="table">
              <thead>
                <tr>
                  <th>
                    <div className="parent-tag">
                      NAME{" "}
                      <div className="filter-btn">
                        <img src={tableup} alt="img" className="img-fluid" />
                        <img src={tabledown} alt="img" className="img-fluid" />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      PROJECT{" "}
                      <div className="filter-btn">
                        <img src={tableup} alt="img" className="img-fluid" />
                        <img src={tabledown} alt="img" className="img-fluid" />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      WALLET ADDRESS{" "}
                      <div className="filter-btn">
                        <img src={tableup} alt="img" className="img-fluid" />
                        <img src={tabledown} alt="img" className="img-fluid" />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      EMAIL{" "}
                      <div className="filter-btn">
                        <img src={tableup} alt="img" className="img-fluid" />
                        <img src={tabledown} alt="img" className="img-fluid" />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      TOTAL SALE{" "}
                      <div className="filter-btn">
                        <img src={tableup} alt="img" className="img-fluid" />
                        <img src={tabledown} alt="img" className="img-fluid" />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      Leader EARNINGS{" "}
                      <div className="filter-btn">
                        <img src={tableup} alt="img" className="img-fluid" />
                        <img src={tabledown} alt="img" className="img-fluid" />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      COMISSION %{" "}
                      <div className="filter-btn">
                        <img src={tableup} alt="img" className="img-fluid" />
                        <img src={tabledown} alt="img" className="img-fluid" />
                      </div>
                    </div>
                  </th>
                  <th>
                    <div className="parent-tag">
                      ACTION{" "}
                      <div className="filter-btn">
                        <img src={tableup} alt="img" className="img-fluid" />
                        <img src={tabledown} alt="img" className="img-fluid" />
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="teammainitexts">
                      <div className="teammainimg">
                        <img
                          src="\assets\myteam\image.png"
                          alt="teaminnerimg"
                          className="teaminnerimg"
                        />
                      </div>
                      <div className="teamtexts">
                        <h6 className="teamhead">Peter</h6>
                        <p className="teampara">Ambassador</p>
                      </div>
                    </div>
                  </td>
                  <td>3magine</td>
                  <td>
                    0x97E6...879A{" "}
                    <a href="#">
                      <img
                        src="\assets\transaction\link-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </a>
                  </td>
                  <td>
                    john@gmail.com{" "}
                    <a href="#">
                      <img
                        src="\assets\transaction\Copy.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </a>
                  </td>
                  <td>
                    <div className="twice-text">
                      <h6>$10</h6>
                      <p>(1.5 ETH + 3.3 USDT)</p>
                    </div>
                  </td>
                  <td>
                    <div className="twice-text">
                      <h6>$500</h6>
                      <p>(1.5 ETH + 3.3 USDT)</p>
                    </div>
                  </td>
                  <td>2.5%</td>
                  <td>
                    <Dropdown className="selectdropdown">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src="\more.png" alt="img" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link to="/teamdetail">
                          {" "}
                          <p className="blacktext">View details</p>
                        </Link>
                        <p className="redtext">Disable Leader</p>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="teammainitexts">
                      <div className="teammainimg">
                        <img
                          src="\assets\myteam\image.png"
                          alt="teaminnerimg"
                          className="teaminnerimg"
                        />
                      </div>
                      <div className="teamtexts">
                        <h6 className="teamhead">Peter</h6>
                        <p className="teampara">Ambassador</p>
                      </div>
                    </div>
                  </td>
                  <td>3magine</td>
                  <td>
                    0x97E6...879A{" "}
                    <a href="#">
                      <img
                        src="\assets\transaction\link-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </a>
                  </td>
                  <td>
                    john@gmail.com{" "}
                    <a href="#">
                      <img
                        src="\assets\transaction\Copy.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </a>
                  </td>
                  <td>
                    <div className="twice-text">
                      <h6>$10</h6>
                      <p>(1.5 ETH + 3.3 USDT)</p>
                    </div>
                  </td>
                  <td>
                    <div className="twice-text">
                      <h6>$500</h6>
                      <p>(1.5 ETH + 3.3 USDT)</p>
                    </div>
                  </td>
                  <td>2.5%</td>
                  <td>
                    <Dropdown className="selectdropdown">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src="\more.png" alt="img" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link to="/teamdetail">
                          {" "}
                          <p className="blacktext">View details</p>
                        </Link>
                        <p className="redtext">Disable Leader</p>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="teammainitexts">
                      <div className="teammainimg">
                        <img
                          src="\assets\myteam\image.png"
                          alt="teaminnerimg"
                          className="teaminnerimg"
                        />
                      </div>
                      <div className="teamtexts">
                        <h6 className="teamhead">Peter</h6>
                        <p className="teampara">Ambassador</p>
                      </div>
                    </div>
                  </td>
                  <td>3magine</td>
                  <td>
                    0x97E6...879A{" "}
                    <a href="#">
                      <img
                        src="\assets\transaction\link-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </a>
                  </td>
                  <td>
                    john@gmail.com{" "}
                    <a href="#">
                      <img
                        src="\assets\transaction\Copy.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </a>
                  </td>
                  <td>
                    <div className="twice-text">
                      <h6>$10</h6>
                      <p>(1.5 ETH + 3.3 USDT)</p>
                    </div>
                  </td>
                  <td>
                    <div className="twice-text">
                      <h6>$500</h6>
                      <p>(1.5 ETH + 3.3 USDT)</p>
                    </div>
                  </td>
                  <td>2.5%</td>
                  <td>
                    <Dropdown className="selectdropdown">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src="\more.png" alt="img" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link to="/teamdetail">
                          {" "}
                          <p className="blacktext">View details</p>
                        </Link>
                        <p className="redtext">Disable Leader</p>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="teammainitexts">
                      <div className="teammainimg">
                        <img
                          src="\assets\myteam\image.png"
                          alt="teaminnerimg"
                          className="teaminnerimg"
                        />
                      </div>
                      <div className="teamtexts">
                        <h6 className="teamhead">Peter</h6>
                        <p className="teampara">Ambassador</p>
                      </div>
                    </div>
                  </td>
                  <td>3magine</td>
                  <td>
                    0x97E6...879A{" "}
                    <a href="#">
                      <img
                        src="\assets\transaction\link-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </a>
                  </td>
                  <td>
                    john@gmail.com{" "}
                    <a href="#">
                      <img
                        src="\assets\transaction\Copy.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </a>
                  </td>
                  <td>
                    <div className="twice-text">
                      <h6>$10</h6>
                      <p>(1.5 ETH + 3.3 USDT)</p>
                    </div>
                  </td>
                  <td>
                    <div className="twice-text">
                      <h6>$500</h6>
                      <p>(1.5 ETH + 3.3 USDT)</p>
                    </div>
                  </td>
                  <td>2.5%</td>
                  <td>
                    <Dropdown className="selectdropdown">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src="\more.png" alt="img" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link to="/teamdetail">
                          {" "}
                          <p className="blacktext">View details</p>
                        </Link>
                        <p className="redtext">Disable Leader</p>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="teammainitexts">
                      <div className="teammainimg">
                        <img
                          src="\assets\myteam\image.png"
                          alt="teaminnerimg"
                          className="teaminnerimg"
                        />
                      </div>
                      <div className="teamtexts">
                        <h6 className="teamhead">Peter</h6>
                        <p className="teampara">Ambassador</p>
                      </div>
                    </div>
                  </td>
                  <td>3magine</td>
                  <td>
                    0x97E6...879A{" "}
                    <a href="#">
                      <img
                        src="\assets\transaction\link-icon.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </a>
                  </td>
                  <td>
                    john@gmail.com{" "}
                    <a href="#">
                      <img
                        src="\assets\transaction\Copy.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </a>
                  </td>
                  <td>
                    <div className="twice-text">
                      <h6>$10</h6>
                      <p>(1.5 ETH + 3.3 USDT)</p>
                    </div>
                  </td>
                  <td>
                    <div className="twice-text">
                      <h6>$500</h6>
                      <p>(1.5 ETH + 3.3 USDT)</p>
                    </div>
                  </td>
                  <td>2.5%</td>
                  <td>
                    <Dropdown className="selectdropdown">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        <img src="\more.png" alt="img" />
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Link to="/teamdetail">
                          {" "}
                          <p className="blacktext">View details</p>
                        </Link>
                        <p className="redtext">Disable Leader</p>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="footer-content">
            <div className="left-f">
              <h6>SHOWING 1-10 OF 145</h6>
            </div>
            <div className="right-f">
              <nav aria-label="Page navigation example">
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="#">
                      {"<"}
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link active" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      4
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      5
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      {">"}
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Datatable;
