import React, { useState, useEffect } from "react";
import "./forgotpassword.scss";
import Header from "../landing/navbar/Header";
import Footerline from "../landing/footer/Footerline";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";

const Newpassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [token, setToken] = useState("");
  useEffect(() => {
    // Get the URLSearchParams object with the current URL
    const params = new URLSearchParams(window.location.search);

    // Get the value of the 'token' parameter
    const tokenUrl = params.get("token");
    setToken(tokenUrl);

    // Use the token as needed

    // You can now use the token in your React component state or perform any other logic with it.
  }, []);
  const validatePassword = (password) => {
    if (password.length < 8) {
      return false;
    }
    if (!/[A-Z]/.test(password)) {
      return false;
    }

    if (!/[a-z]/.test(password)) {
      return false;
    }

    if (!/[^A-Za-z0-9]/.test(password)) {
      return false;
    }
    return true;
  };

  const handleResetPassword = async () => {
    setIsLoading(true);

    if (!validatePassword(password)) {
      const errorMessage =
        "Password must be at least 8 characters long, at least one uppercase and one lowercase letter, one special character.";

      setErrors({ password: errorMessage });
      setIsLoading(false);
      return;
    }

    if (password !== confirmPassword) {
      setErrors({ confirmPassword: "Passwords do not match" });
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${Api_Url}/auth/users/reset-password`,
        { token, password, confirmPassword }
      );
      console.log(response.data); // Log the response data
      setIsLoading(false);
      history.push("/emailsent");
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <Header />
      <section className="forgotmain">
        <div className="forgotinner">
          <h5 className="forgothead">create new password</h5>
          <p className="forgotpara ">
            Please create a new password that you don’t use on any other site
          </p>
          <div className="material-textfield ">
            <input
              name="password"
              placeholder="New Password"
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setErrors("");
              }}
            />
            <label>New Password</label>
          </div>
          <p className="redpara">
            {errors.password && (
              <span className="text-danger">{errors.password}</span>
            )}
          </p>
          <div className="material-textfield ">
            <input
              name="confirmPassword"
              placeholder="Confirm Password"
              type="password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setErrors("");
              }}
            />
            <label>Confirm Password</label>
          </div>
          <p className="redpara">
            {errors.confirmPassword && (
              <span className="text-danger">{errors.confirmPassword}</span>
            )}
          </p>

          <button onClick={handleResetPassword} className="greybtn w-100">
            Change
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
            >
              <path
                d="M6.86318 2.3761V3.82075H8.30785V2.3761H6.86318ZM8.30785 3.82075V5.26471H9.7518V3.82075H8.30785ZM9.7518 5.26471V6.70936H0.831635V8.15122H9.7518V9.59516H11.1965V8.15122H13V6.70936H11.1965V5.26471H9.7518ZM9.7518 9.59516H8.30785V11.0398H9.7518V9.59516ZM8.30785 11.0398H6.86318V12.4838H8.30785V11.0398Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </section>
      <Footerline />
    </>
  );
};

export default Newpassword;
