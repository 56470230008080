import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getLEIAInsuranceContract } from "../../utils/contractHelpers";

export const Listed = () => {
  const { account } = useWeb3React();
  const web3 = useWeb3();
  const tokenAddress = environment.LEIAInsurance;
  const contract = getLEIAInsuranceContract(tokenAddress, web3);
  const IsActivated = useCallback(async () => {
    try {
      var approved;
      approved = await contract.methods.isActivated().call();
      return approved;
    } catch (err) {
      console.log("aganssaf", err);
    }
  }, [account, contract]);

  return { IsActivated: IsActivated };
};

export default Listed;
