import React, { useState } from "react";
import "./leaderprofile.scss";
import Header from "../landing/navbar/Header";
import Footerline from "../landing/footer/Footerline";
import { Dropdown, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";

const Existingleaders = () => {
  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [show11, setShow11] = useState(false);
  const handleClose11 = () => setShow11(false);
  const handleShow11 = () => setShow11(true);
  const [selectedProject, setSelectedProject] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [allProjects] = useState(["TOKN", "TON", "3MAG", "LEDO", "ZILLA"]);
  const [name, setName] = useState("");
  const [addr, setAddr] = useState("");
  const [email, setEmail] = useState("");

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDropdownSelect = (project) => {
    setSelectedProject(project);
    setSearchTerm(project); // Update input value with the selected project
  };

  const filteredProjects = allProjects.filter((project) =>
    project.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <>
      <Header />
      <section className="mainproject">
        <div className="theme-container">
          <div className="parent">
            <div className="left">
              <h2>Add Leaders</h2>
            </div>
            <div className="right">
              <div className="innerright">
                <input type="text" placeholder="Search..." />
                <img
                  src="\assets\dashboardassets\search-normal.svg"
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <div className="dropbtn">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    Leader Roles
                    <img
                      src="\assets\dashboardassets\arrowdropdown.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="main-card">
            <div className="table-responsive">
              <table className="table">
                <thead className="tblheadss">
                  <tr>
                    <th>
                      name{" "}
                      <img
                        src="\Group120.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </th>
                    <th>
                      Wallet Address{" "}
                      <img
                        src="\Group120.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </th>
                    <th>
                      Email{" "}
                      <img
                        src="\Group120.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </th>
                    <th>
                      Total Projects{" "}
                      <img
                        src="\Group120.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </th>
                    <th>
                      Total Sale{" "}
                      <img
                        src="\Group120.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </th>
                    <th>
                      Leader Earnings{" "}
                      <img
                        src="\Group120.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="parenttbl">
                        <div className="left">
                          <img
                            src="\assets\dashboardassets\profileuser.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="right">
                          <h6>Debra Harris</h6>
                          <p>God Leader</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="para">
                        0x97E6...879A{" "}
                        <img
                          src="\assets\dashboardassets\Copy.svg"
                          alt="img"
                          className="copy"
                        />
                      </p>
                    </td>
                    <td>
                      {" "}
                      <p className="para">john@gmail.com</p>
                    </td>
                    <td>52</td>
                    <td>
                      <h6>10,214</h6>
                    </td>
                    <td>
                      <h6>$885</h6>
                    </td>
                    <td>
                      <button className="whitebtn" onClick={handleShow3}>
                        Send Invite
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="footer-content">
              <div className="left-f">
                <h6>SHOWING 1-10 OF 145</h6>
              </div>
              <div className="right-f">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link" href="#">
                        {"<"}
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link active" href="#">
                        1
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        4
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        5
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        {">"}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footerline />

      <Modal
        className="walletconnectmodal addteammembermodal"
        show={show3}
        onHide={handleClose3}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Invite Leader</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="material-textfield ">
            <input
              type="text"
              name="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label>Name</label>
          </div>
          <div className="material-textfield ">
            <input
              type="text"
              name="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />
            <label>Email</label>
          </div>
          <div className="material-textfield ">
            <input
              type="text"
              name="text"
              placeholder="Wallet address"
              value={addr}
              onChange={(e) => setAddr(e.target.value)}
            />
            <label>Wallet address</label>
          </div>

          <Dropdown className="selectdropdown">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="material-textfield ">
                <input
                  type="text"
                  name="text"
                  placeholder="Select Project"
                  value={searchTerm}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                <label>Select Project</label>
                <img
                  src="\assets\dashboardassets\arrowdropdown.svg"
                  alt="arrowimg"
                  className="arrowimg"
                />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="dropdownmenumain">
                {filteredProjects.map((project, index) => (
                  <p
                    key={index}
                    className="dropitem"
                    onClick={() => handleDropdownSelect(project)}
                  >
                    {project}
                  </p>
                ))}
              </div>
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="selectdropdown">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="material-textfield ">
                <input
                  type="text"
                  name="text"
                  placeholder="Select Role"
                  value={searchTerm}
                  onChange={handleInputChange}
                  autoComplete="off"
                />
                <label>Select Role</label>
                <img
                  src="\assets\dashboardassets\arrowdropdown.svg"
                  alt="arrowimg"
                  className="arrowimg"
                />
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className="dropdownmenumain">
                <p className="dropitem">Super Leader</p>
              </div>
            </Dropdown.Menu>
          </Dropdown>

          <div className="modalbtns">
            <button className="whitebtn" onClick={handleClose3}>
              Cancel
            </button>
            <button
              className="greybtn"
              onClick={() => {
                handleClose3();
                handleShow1();
              }}
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="walletconnectmodal addteammembermodal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Invite Leader</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addedmodal">
            <svg
              className="addedimg"
              xmlns="http://www.w3.org/2000/svg"
              width="68"
              height="68"
              viewBox="0 0 68 68"
              fill="none"
            >
              <path
                d="M34.5 7C19.8985 7 8 19.123 8 34C8 48.877 19.8985 61 34.5 61C49.1015 61 61 48.877 61 34C61 19.123 49.1015 7 34.5 7ZM45.1 36.025H36.4875V44.8C36.4875 45.907 35.5865 46.825 34.5 46.825C33.4135 46.825 32.5125 45.907 32.5125 44.8V36.025H23.9C22.8135 36.025 21.9125 35.107 21.9125 34C21.9125 32.893 22.8135 31.975 23.9 31.975H32.5125V23.2C32.5125 22.093 33.4135 21.175 34.5 21.175C35.5865 21.175 36.4875 22.093 36.4875 23.2V31.975H45.1C46.1865 31.975 47.0875 32.893 47.0875 34C47.0875 35.107 46.1865 36.025 45.1 36.025Z"
                fill="#53FFEA"
              />
            </svg>
            <h6 className="addedhead">
              Are you sure you want to invite John Doe?
            </h6>
          </div>

          <div className="modalbtns">
            <button className="whitebtn" onClick={handleClose1}>
              Cancel
            </button>
            <button
              className="greybtn"
              onClick={() => {
                handleClose1();
                handleShow11();
              }}
            >
              Add
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="walletconnectmodal addteammembermodal"
        show={show11}
        onHide={handleClose11}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Invite Leader</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addedmodal">
            <img
              src="\assets\myteam\tick.svg"
              alt="addedimg"
              className="addedimg"
            />
            <h6 className="addedhead">Team member successfully added</h6>
            <button
              className="greybtn w-100"
              onClick={() => {
                handleClose11();
                // history.push("/leaders");
                // settoggle(false);
              }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Existingleaders;
