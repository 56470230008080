import React from "react";
import "./project.scss";
import Dropdown from "react-bootstrap/Dropdown";
import Header from "../landing/navbar/Header";
import Footerline from "../landing/footer/Footerline";
const Project = () => {
  return (
    <>
      <Header />
      <section className="mainproject">
        <div className="theme-container">
          <div className="parent">
            <div className="left">
              <h2>All Projects</h2>
            </div>
            <div className="right">
              <div className="innerright">
                <input type="text" placeholder="Search..." />
                <img
                  src="\assets\dashboardassets\search-normal.svg"
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <div className="dropbtn">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    No. Of Purchases
                    <img
                      src="\assets\dashboardassets\arrowdropdown.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      Another action
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">
                      Something else
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="main-card">
            <div className="table-responsive">
              <table className="table">
                <thead className="tblheadss">
                  <tr>
                    <th>
                      name{" "}
                      <img
                        src="\Group120.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </th>
                    <th>
                      Code{" "}
                      <img
                        src="\Group120.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </th>
                    <th>
                      No. of purchases{" "}
                      <img
                        src="\Group120.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </th>
                    <th>
                      Total User{" "}
                      <img
                        src="\Group120.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </th>
                    <th>
                      My Earning{" "}
                      <img
                        src="\Group120.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </th>
                    <th>
                      My commission %{" "}
                      <img
                        src="\Group120.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div className="parenttbl">
                        <div className="left">
                          <img
                            src="\Frame48095765.png"
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="right">
                          <h6>TOKN</h6>
                          <p>TokenXYZ</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="para">
                        2541085474125874{" "}
                        <img
                          src="\assets\dashboardassets\Copy.svg"
                          alt="img"
                          className="copy"
                        />
                      </p>
                    </td>
                    <td>
                      {" "}
                      <p className="para">~$23K</p>
                    </td>
                    <td>3.5K</td>
                    <td>
                      <h6>~$2,112</h6>
                      <p>(1.5 ETH + 3.3 USDT)</p>
                    </td>
                    <td>
                      {" "}
                      <p className="para">2.5%</p>
                    </td>
                    <td>
                      <img src="\more.png" alt="img" className="img-fluid" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="parenttbl">
                        <div className="left">
                          <img
                            src="\Frame48095765.png"
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="right">
                          <h6>TOKN</h6>
                          <p>TokenXYZ</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="para">
                        2541085474125874{" "}
                        <img
                          src="\assets\dashboardassets\Copy.svg"
                          alt="img"
                          className="copy"
                        />
                      </p>
                    </td>
                    <td>
                      {" "}
                      <p className="para">~$23K</p>
                    </td>
                    <td>3.5K</td>
                    <td>
                      <h6>~$2,112</h6>
                      <p>(1.5 ETH + 3.3 USDT)</p>
                    </td>
                    <td>
                      {" "}
                      <p className="para">2.5%</p>
                    </td>
                    <td>
                      <img src="\more.png" alt="img" className="img-fluid" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="parenttbl">
                        <div className="left">
                          <img
                            src="\Frame48095765.png"
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div className="right">
                          <h6>TOKN</h6>
                          <p>TokenXYZ</p>
                        </div>
                      </div>
                    </td>
                    <td>
                      <p className="para">
                        2541085474125874{" "}
                        <img
                          src="\assets\dashboardassets\Copy.svg"
                          alt="img"
                          className="copy"
                        />
                      </p>
                    </td>
                    <td>
                      {" "}
                      <p className="para">~$23K</p>
                    </td>
                    <td>3.5K</td>
                    <td>
                      <h6>~$2,112</h6>
                      <p>(1.5 ETH + 3.3 USDT)</p>
                    </td>
                    <td>
                      {" "}
                      <p className="para">2.5%</p>
                    </td>
                    <td>
                      <img src="\more.png" alt="img" className="img-fluid" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="footer-content">
              <div className="left-f">
                <h6>SHOWING 1-10 OF 145</h6>
              </div>
              <div className="right-f">
                <nav aria-label="Page navigation example">
                  <ul class="pagination">
                    <li class="page-item">
                      <a class="page-link" href="#">
                        {"<"}
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link active" href="#">
                        1
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        4
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        5
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        {">"}
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footerline />
    </>
  );
};

export default Project;
