import React, { useState } from "react";
import "./forgotpassword.scss";
import Header from "../landing/navbar/Header";
import Footerline from "../landing/footer/Footerline";
import { Link } from "react-router-dom";
import axios from "axios";
import { Api_Url } from "../../utils/ApiUrl";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Forgotpassword = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const validateEmail = (email) => {
    const regex = /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/;
    return regex.test(email);
  };
  const handleForgotPassword = async () => {
    setIsLoading(true);
    if (!validateEmail(email)) {
      setErrors({ email: "Please enter a valid email address" });
      setIsLoading(false);
      return;
    }
    try {
      const response = await axios.post(
        `${Api_Url}/auth/users/forget-password`,
        { email }
      );
      console.log(response.data); // Log the response data
      setIsLoading(false);
      history.push("/emailsent");
    } catch (error) {
      setErrors({
        email:
          error.response.data.statusCode == "404"
            ? "user does not Exist!"
            : error.response.data.message,
      });
      console.error("Error:", error);
      setIsLoading(false);
    }
  };
  return (
    <>
      <Header />
      <section className="forgotmain">
        <div className="forgotinner">
          <h5 className="forgothead">Forgot Password</h5>
          <p className="forgotpara">
            Kindly enter your registered email address.
          </p>
          <div className="material-textfield ">
            <input
              onChange={(e) => {
                setEmail(e.target.value);
                setErrors("");
              }}
              name="email"
              placeholder="Email Address"
              value={email}
              type="text"
            />
            <label>Email Address</label>
          </div>
          <p className="redpara">
            {errors.email && (
              <span className="text-danger">{errors.email}</span>
            )}
          </p>
          <button onClick={handleForgotPassword} className="greybtn w-100">
            Request Link
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
            >
              <path
                d="M6.86318 2.3761V3.82075H8.30785V2.3761H6.86318ZM8.30785 3.82075V5.26471H9.7518V3.82075H8.30785ZM9.7518 5.26471V6.70936H0.831635V8.15122H9.7518V9.59516H11.1965V8.15122H13V6.70936H11.1965V5.26471H9.7518ZM9.7518 9.59516H8.30785V11.0398H9.7518V9.59516ZM8.30785 11.0398H6.86318V12.4838H8.30785V11.0398Z"
                fill="black"
              />
            </svg>
          </button>
        </div>
      </section>
      <Footerline />
    </>
  );
};

export default Forgotpassword;
