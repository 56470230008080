import React from 'react'
import './footer.scss'

const Footerline = () => {
  return (
   <section className="footerline">
    <p className='mainfooterline'>Copyright © 2024 Gems. All Rights Reserved.</p>
   </section>
  )
}

export default Footerline