import React, { useState, useEffect } from "react";
import "./viewdetail.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Header from "../landing/navbar/Header";
import DatatableLeaders from "./DataTable/Datatable";
import Datatable from "../AllTransaction/DataTable/Datatable";
import { Modal } from "react-bootstrap";
import Footerline from "../landing/footer/Footerline";
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import moment from "moment";
import LeadersDatatable from "./LeadersDatatable";
import TransactionsDatatable from "./TransactionsDatatable";
import { Dropdown } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

const ViewDetail = ({
  usdPrice,
  tomiPrice,
  btcPrice,
  pepePrice,
  uniPrice,
  linkPrice,
  dopPrice,
}) => {
  const history = useHistory();
  const project = localStorage.getItem("project");

  const tok = localStorage.getItem("accessToken");
  const user = JSON.parse(localStorage.getItem("user"));

  const [leadersLoader, setLeadersLoader] = useState(false);
  const [transactionApiCalled, setTransactionApiCalled] = useState(false);

  const [leaderscount, setleaderscount] = useState(null);
  const [roleTypeFilter, setRoleTypeFilter] = useState("");
  const [tabKey, setTabKey] = useState("transactions");
  const [showchange, setShowchange] = useState(false);
  const [id, setId] = useState();
  const [getdata, setGetData] = useState();
  const [noOfLeaders, setNoOfLeaders] = useState();

  const [transactions, setTransactions] = useState([]);

  const [search, setSearch] = useState("");
  const [searchBy, setSearchBy] = useState("name");

  const [search0, setSearch0] = useState("");
  const [page, setPage] = useState(0);
  const [pageCountAgent, setPageCountAgent] = useState(0);

  const [earnings, setEarnings] = useState(null);

  const [agents, setAgents] = useState([]);

  const [offset, setOffset] = useState(1);
  const [count, setCount] = useState(0);
  const [limit, setLimit] = useState(1);
  const [pages, setPages] = useState([]);
  const [code, setCode] = useState([]);
  const [page1, setPage1] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [leaderNames, setLeaderNames] = useState([]);
  const [leaderNames2, setLeaderNames2] = useState([]);

  const [leaderFilter, setLeaderFilter] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryNames, setSearchQueryNames] = useState("");

  const [orderField, setOrderField] = useState("transactionTime");
  const [orderDirection, setOrderDirection] = useState("-1");

  const [orderFieldAgents, setOrderFieldAgents] = useState("directAmountInUsd");
  const [orderDirectionAgents, setOrderDirectionAgents] = useState("-1");

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);

  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);

  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);
  const [leadersData, setLeadersData] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [role, setRole] = useState("");

  const getData = async (_id) => {
    var config = {
      method: "get",
      url: `${Api_Url}/users/${id}?privateSale=${project}`,
      headers: {
        authorization: `Bearer ` + tok,
      },
      data: {
        _id: id,
      },
    };
    axios(config)
      .then(function (response) {
        // console.log("user coount eth", response);
        // const resData = response?.data?.data;
        setGetData(response?.data?.data);
        setUserRole(response?.data?.data.role);
        setRole(response?.data?.data.role);
        // setleaderscount(resData);
        // setMinimumBuy(resData?.minimumBuy);
        // setAccessCodeToggle(resData?.accessCodeEnabled);
        // setSpecialAccess(resData?.specialAccess);
      })
      .catch(function (error) {});
  };

  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    setId(val.searchParams.get("id"));
    localStorage.setItem("currentId", val.searchParams.get("id"));
    // window.scroll(0, 0);
    if (id) {
      getData();
    }
  }, [id]);

  const GetAgents = () => {
    // off?.preventDefault();
    setLeadersLoader(true);
    const val = localStorage.getItem("accessToken");
    let valu = null;

    var config = {
      method: "get",
      url: `${Api_Url}/users/${id}/leaders?limit=${10}&offset=${
        page + 1
      }&privateSale=${project}&role=${getdata?.role}`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };

    let dynamicQuery = config.url;

    if (leaderFilter) {
      dynamicQuery += `&name=${encodeURIComponent(leaderFilter)}`;
    }

    if (roleTypeFilter) {
      dynamicQuery += `&leaderType=${encodeURIComponent(roleTypeFilter)}`;
    }
    // if (searchQuery) {
    //   dynamicQuery += `&search=${encodeURIComponent(searchQuery)}`;
    // }

    if (searchQuery) {
      dynamicQuery += `&${searchBy}=${searchQuery}`;
    }
    if (orderFieldAgents) {
      dynamicQuery += `&orderField=${orderFieldAgents}&orderDirection=${orderDirectionAgents}`;
    }
    config.url = dynamicQuery;

    axios(config)
      .then(function (response) {
        const resData = response?.data?.data;
        setCount(response.data.data.count);
        setAgents(resData?.agents);
        setNoOfLeaders(resData?.team);
        let arr = Array.from(Array(parseInt(response.data.data.pages)).keys());
        setPageCountAgent(response?.data?.data?.pages);

        setPages(arr);
        // setSearch('')

        setLeadersLoader(false);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/login");
        }
        setLeadersLoader(false);
      });
  };

  const getTransactionsData = () => {
    setTransactionApiCalled(false);

    var config = {
      method: "get",
      url: `${Api_Url}/users/${id}/all-transactions?limit=10&offset=${
        page1 + 1
      }&privateSale=${project}`,

      headers: {
        Authorization: "Bearer " + tok,
      },
    };

    let dynamicQuery = config.url;

    // if (search) {
    //   dynamicQuery += `&by=${search}`;
    // }
    if (search) {
      dynamicQuery += `&${searchBy}=${search}`;
    }
    if (orderField) {
      dynamicQuery += `&orderField=${orderField}&orderDirection=${orderDirection}`;
    }

    if (roleTypeFilter) {
      dynamicQuery += `&leaderType=${encodeURIComponent(roleTypeFilter)}`;
    }
    config.url = dynamicQuery;
    axios(config)
      .then(function (res) {
        setTransactions(res?.data?.data?.transactions);
        setPageCount(res?.data?.data?.pages);
        setCount(res?.data.data.count);
        setTransactionApiCalled(true);
      })
      .catch(function (error) {
        setTransactionApiCalled(true);
        if (error?.response?.status == 401) {
          localStorage.removeItem("accessToken");
          history.push("/login");
        }
      });
  };

  const getLeaderNames = () => {
    const val = localStorage.getItem("accessToken");

    var config = {
      method: "get",
      url: `${Api_Url}/users/${id}/leaders-names?limit=${500}&role=${
        getdata?.role
      }`,
      headers: {
        Authorization: "Bearer " + val,
      },
    };
    let dynamicQuery = config.url;

    if (searchQueryNames) {
      dynamicQuery += `&name=${encodeURIComponent(searchQueryNames)}`;
    }
    axios(config)
      .then(function (response) {
        setLeaderNames(response?.data?.data);
        setLeaderNames2(response?.data?.data);
      })
      .catch(function (error) {
        if (error?.response?.status == 401) {
          // localStorage.removeItem("accessToken");
          // history.push("/login");
        }
      });
  };

  useEffect(() => {
    if (id && tabKey == "leaders" && getdata) {
      GetAgents();
      getLeaderNames();
    }
  }, [
    getdata,
    id,
    searchQuery,
    leaderFilter,
    roleTypeFilter,
    orderDirectionAgents,
    orderFieldAgents,
    tabKey,
    page,
  ]);

  useEffect(() => {
    if (id && usdPrice && tabKey == "transactions") {
      getTransactionsData();
    }
  }, [
    usdPrice,
    id,
    search,
    orderDirection,
    orderField,
    tabKey,
    page1,
    roleTypeFilter,
  ]);

  const handlePageChange = (e) => {
    const selectedPage = e.selected;

    setPage1(selectedPage);
  };

  const handleAgentsPageChange = (e) => {
    const selectedPage = e.selected;

    setPage(selectedPage);
  };

  const GetSearch = (searchKey) => {
    setSearch0(searchKey);
    if (searchKey === "") {
      setLeaderFilter("");
      setLeaderNames(leaderNames2);
    } else if (searchKey?.length > 1) {
      const results = leaderNames2.filter((item) =>
        item.name?.toLowerCase().includes(searchKey?.toLowerCase())
      );
      setLeaderNames(results);
      // SearchByName(searchKey);
    }
  };

  function convertToTitleCase(input) {
    if (input) {
      let words = input
        .split("_")
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );

      if (words[0] === "mini" || words[0] === "Mini") {
        return "Ambassador";
      }
      if (
        words[words.length - 1].toLowerCase() === "agent" &&
        (words[0] != "mini" || words[0] != "Mini")
      ) {
        words[words.length - 1] = "Leader";
      }
      if (words[0] === "super" || words[0] === "Super") {
        return "Super";
      } else if (words[0] !== "agent") {
        return words.join(" ");
      } else {
        return "Leader";
      }
    } else {
      return "";
    }
  }
  return (
    <>
      <Header />

      <section className="viewdetail">
        <div className="theme-container veiwdetaileinnerpage">
          <div className="mb-5">
            <a onClick={() => history.goBack()} className="back-button">
              Back
            </a>
          </div>

          <div className="main-heading">
            <div className="left-heading">
              <h6>{getdata?.name}</h6>
              <p>
                {convertToTitleCase(getdata?.role)} | {getdata?.accessCode}
              </p>
            </div>
            {/* <div className="twice-btn">
              <div className="set-custom-claim-text">
                <div className="parent-toggle-switch-custom">
                  <div class="custom-toggle">
                    <input type="checkbox" class="checkbox" id="checkbox" />
                    <label class="switch" for="checkbox">
                      <span class="slider"></span>
                    </label>
                  </div>
                </div>
                <h6>Disable this Code</h6>
              </div>

              <button className="btn-edit">
                Edit
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                >
                  <path
                    d="M4.1377 11.2842H5.24548V12.392H4.1377V11.2842Z"
                    fill="black"
                  />
                  <path
                    d="M0.814697 12.3916H1.92248V13.4994H0.814697V12.3916Z"
                    fill="black"
                  />
                  <path
                    d="M0.814697 11.2842H1.92248V12.392H0.814697V11.2842Z"
                    fill="black"
                  />
                  <path
                    d="M0.814697 10.1768H1.92248V11.2845H0.814697V10.1768Z"
                    fill="black"
                  />
                  <path
                    d="M1.92236 12.3916H3.03015V13.4994H1.92236V12.3916Z"
                    fill="black"
                  />
                  <path
                    d="M7.46118 12.3916H8.56896V13.4994H7.46118V12.3916Z"
                    fill="black"
                  />
                  <path
                    d="M6.35352 12.3916H7.4613V13.4994H6.35352V12.3916Z"
                    fill="black"
                  />
                  <path
                    d="M8.56885 12.3916H9.67663V13.4994H8.56885V12.3916Z"
                    fill="black"
                  />
                  <path
                    d="M8.56885 6.85254H9.67663V7.96032H8.56885V6.85254Z"
                    fill="black"
                  />
                  <path
                    d="M9.67676 5.74512H10.7845V6.8529H9.67676V5.74512Z"
                    fill="black"
                  />
                  <path
                    d="M10.7844 4.6377H11.8922V5.74548H10.7844V4.6377Z"
                    fill="black"
                  />
                  <path
                    d="M11.8923 3.5293H13.0001V4.63708H11.8923V3.5293Z"
                    fill="black"
                  />
                  <path
                    d="M9.67676 3.5293H10.7845V4.63708H9.67676V3.5293Z"
                    fill="black"
                  />
                  <path
                    d="M8.56885 2.42188H9.67663V3.52966H8.56885V2.42188Z"
                    fill="black"
                  />
                  <path
                    d="M11.8923 2.42188H13.0001V3.52966H11.8923V2.42188Z"
                    fill="black"
                  />
                  <path
                    d="M10.7844 1.31445H11.8922V2.42224H10.7844V1.31445Z"
                    fill="black"
                  />
                  <path
                    d="M9.67676 1.31445H10.7845V2.42224H9.67676V1.31445Z"
                    fill="black"
                  />
                  <path
                    d="M9.67676 12.3916H10.7845V13.4994H9.67676V12.3916Z"
                    fill="black"
                  />
                  <path
                    d="M10.7844 12.3916H11.8922V13.4994H10.7844V12.3916Z"
                    fill="black"
                  />
                  <path
                    d="M11.8923 12.3916H13.0001V13.4994H11.8923V12.3916Z"
                    fill="black"
                  />
                  <path
                    d="M4.1377 6.85254H5.24548V7.96032H4.1377V6.85254Z"
                    fill="black"
                  />
                  <path
                    d="M3.03027 12.3916H4.13806V13.4994H3.03027V12.3916Z"
                    fill="black"
                  />
                  <path
                    d="M3.03027 10.1768H4.13806V11.2845H3.03027V10.1768Z"
                    fill="black"
                  />
                  <path
                    d="M5.24561 10.1768H6.35339V11.2845H5.24561V10.1768Z"
                    fill="black"
                  />
                  <path
                    d="M5.24561 5.74512H6.35339V6.8529H5.24561V5.74512Z"
                    fill="black"
                  />
                  <path
                    d="M6.35352 9.06836H7.4613V10.1761H6.35352V9.06836Z"
                    fill="black"
                  />
                  <path
                    d="M7.46118 7.96094H8.56896V9.06872H7.46118V7.96094Z"
                    fill="black"
                  />
                  <path
                    d="M6.35352 4.6377H7.4613V5.74548H6.35352V4.6377Z"
                    fill="black"
                  />
                  <path
                    d="M7.46118 3.5293H8.56896V4.63708H7.46118V3.5293Z"
                    fill="black"
                  />
                  <path
                    d="M1.92236 9.06836H3.03015V10.1761H1.92236V9.06836Z"
                    fill="black"
                  />
                  <path
                    d="M3.03027 7.96094H4.13806V9.06872H3.03027V7.96094Z"
                    fill="black"
                  />
                </svg>
              </button>

              <button
                // className={blockUnblock ? "btn-del btnunblock" : "btn-del"}
                className="btn-del"
                onClick={() => setShow3(true)}
              >
                Block
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="15"
                  viewBox="0 0 14 15"
                  fill="none"
                >
                  <g clip-path="url(#clip0_635_10114)">
                    <path
                      d="M7 13.125H8.25001V14.375H7V13.125Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M5.75 13.125H7.00001V14.375H5.75V13.125Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M4.50024 13.125H5.75025V14.375H4.50024V13.125Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M3.25 13.125H4.50001V14.375H3.25V13.125Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M9.5 13.125H10.75V14.375H9.5V13.125Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M8.25024 13.125H9.50025V14.375H8.25024V13.125Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M4.50024 4.375H5.75025V5.62501H4.50024V4.375Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M2.00024 4.375H3.25025V5.62501H2.00024V4.375Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M7 4.375H8.25001V5.62501H7V4.375Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M7.625 6.875H8.87501V8.12501H7.625V6.875Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M7.625 8.125H8.87501V9.37501H7.625V8.125Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M7.625 9.375H8.87501V10.625H7.625V9.375Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M7.625 10.625H8.87501V11.875H7.625V10.625Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M5.75 4.375H7.00001V5.62501H5.75V4.375Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M3.25 4.375H4.50001V5.62501H3.25V4.375Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M9.5 4.375H10.75V5.62501H9.5V4.375Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M8.25024 4.375H9.50025V5.62501H8.25024V4.375Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M10.75 1.875H12V3.12501H10.75V1.875Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M5.125 10.625H6.37501V11.875H5.125V10.625Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M5.125 9.375H6.37501V10.625H5.125V9.375Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M5.125 8.125H6.37501V9.37501H5.125V8.125Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M5.125 6.875H6.37501V8.12501H5.125V6.875Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M2.00024 5.625H3.25025V6.87501H2.00024V5.625Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M2.00024 10.625H3.25025V11.875H2.00024V10.625Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M2.00024 11.875H3.25025V13.125H2.00024V11.875Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M2.00024 9.375H3.25025V10.625H2.00024V9.375Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M2.00024 8.125H3.25025V9.37501H2.00024V8.125Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M2.00024 6.875H3.25025V8.12501H2.00024V6.875Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M10.75 4.375H12V5.62501H10.75V4.375Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M10.75 5.625H12V6.87501H10.75V5.625Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M10.75 10.625H12V11.875H10.75V10.625Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M10.75 11.875H12V13.125H10.75V11.875Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M10.75 9.375H12V10.625H10.75V9.375Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M10.75 8.125H12V9.37501H10.75V8.125Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M10.75 6.875H12V8.12501H10.75V6.875Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M8.25024 1.875H9.50025V3.12501H8.25024V1.875Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M5.75 0.625H7.00001V1.87501H5.75V0.625Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M4.50024 0.625H5.75025V1.87501H4.50024V0.625Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M7 0.625H8.25001V1.87501H7V0.625Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M8.25024 0.625H9.50025V1.87501H8.25024V0.625Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M9.5 1.875H10.75V3.12501H9.5V1.875Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M3.25 1.875H4.50001V3.12501H3.25V1.875Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M2.00024 1.875H3.25025V3.12501H2.00024V1.875Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M7 1.875H8.25001V3.12501H7V1.875Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M4.50024 1.875H5.75025V3.12501H4.50024V1.875Z"
                      fill="#FE0000"
                    />
                    <path
                      d="M5.75 1.875H7.00001V3.12501H5.75V1.875Z"
                      fill="#FE0000"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_635_10114">
                      <rect
                        width="14"
                        height="14"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div> */}
          </div>
          <div className="bottom-cards">
            <div className="single-card">
              <img
                src="\assets\leaders\noofpurchases.svg"
                alt="img"
                className="img-fluid"
              />
              <p>Number of Purchases</p>
              <h6>
                {/* {getdata?.totalDirectSales ||
                  "0" + getdata?.totalInDirectSales ||
                  "0"}{" "} */}
                {getdata?.totalSales}
              </h6>
            </div>
            <div className="single-card">
              <img
                src="\assets\leaders\tokpurchased.svg"
                alt="img"
                className="img-fluid"
              />
              <p>{project} Purchased</p>

              <h6>
                {" "}
                {parseInt(getdata?.totalDop?.toFixed(2)).toLocaleString(
                  "en-US"
                )}{" "}
              </h6>

              <div className="valuemaindiv">
                <div className="valuemaindiv">
                  <Dropdown align="end">
                    <Dropdown.Toggle id="dropdown-basic">
                      <img
                        src="\assets\dashboardassets\Caret_Down_MD.svg"
                        alt="arrowimg"
                        className="arrowimg"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="teamsdrops">
                        <div className="innerteamdrop">
                          <p className="teamdroppara">ETH</p>
                          <h6 className="teamdrophead">
                            <span></span>
                            {/* ? (user?.totalEth + user?.totalEthIndirect)?.toFixed(4) */}
                            {getdata
                              ? parseFloat(getdata?.totalEth)?.toFixed(5)
                              : ""}{" "}
                          </h6>
                        </div>
                        <div className="innerteamdrop">
                          <p className="teamdroppara">USDT</p>

                          <h6 className="teamdrophead">
                            <span></span>
                            {getdata
                              ? parseFloat(
                                  getdata?.totalUsdt?.toFixed(4)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                        <div className="innerteamdrop">
                          <p className="teamdroppara">DOP</p>

                          <h6 className="teamdrophead">
                            {getdata
                              ? parseFloat(
                                  getdata?.totalDopToken?.toFixed(5)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                        {project == "DOP" ? (
                          <div className="innerteamdrop">
                            <p className="teamdroppara">TOMI</p>

                            <h6 className="teamdrophead">
                              {getdata
                                ? parseFloat(
                                    getdata?.totalTomi?.toFixed(5)
                                  )?.toLocaleString("en-US")
                                : 0}{" "}
                            </h6>
                          </div>
                        ) : null}
                        <div className="innerteamdrop">
                          <p className="teamdroppara">WBTC</p>

                          <h6 className="teamdrophead">
                            {getdata
                              ? parseFloat(getdata?.totalWbtc?.toFixed(6))
                              : 0}{" "}
                          </h6>
                        </div>{" "}
                        <div className="innerteamdrop">
                          <p className="teamdroppara">USDC</p>

                          <h6 className="teamdrophead">
                            {getdata
                              ? parseFloat(
                                  getdata?.totalUsdc?.toFixed(4)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                        <div className="innerteamdrop">
                          <p className="teamdroppara">PEPE</p>

                          <h6 className="teamdrophead">
                            {getdata
                              ? parseFloat(
                                  getdata?.totalPepe?.toFixed(4)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                        <div className="innerteamdrop">
                          <p className="teamdroppara">LINK</p>

                          <h6 className="teamdrophead">
                            {getdata
                              ? parseFloat(
                                  getdata?.totalLink?.toFixed(4)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                        <div className="innerteamdrop">
                          <p className="teamdroppara">UNI</p>

                          <h6 className="teamdrophead">
                            {getdata
                              ? parseFloat(
                                  getdata?.totalUni?.toFixed(4)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              {/* <div className="bottom-text">
                <p className="uni-text">
                  <span></span>
                  {getdata?.totalUsdt || "0"} USD
                </p>
              </div> */}
            </div>
            <div className="single-card">
              <img
                src="\assets\leaders\totalpurchases.svg"
                alt="img"
                className="img-fluid"
              />
              <p>Total Purchases</p>
              {/* <h6>0~$23K </h6>
              <div className="bottom-text">
                <p className="uni-text">
                  <span></span>
                  1.3 ETH
                </p>
                <p className="uni-text">
                  <span></span>~ 2.3 USDT
                </p>
              </div> */}

              {usdPrice && getdata ? (
                <>
                  <h6 className="datahead">
                    ~$
                    {parseFloat(
                      (
                        usdPrice * getdata?.totalEthDirect +
                        getdata?.totalUsdtDirect +
                        tomiPrice * getdata?.totalTomiDirect +
                        dopPrice * getdata?.totalDopTokenDirect +
                        btcPrice * getdata?.totalWbtcDirect +
                        getdata?.totalUsdcDirect +
                        (pepePrice * getdata?.totalPepeDirect || 0) +
                        (linkPrice * getdata?.totalLinkDirect || 0) +
                        (uniPrice * getdata?.totalUniDirect || 0)
                      )?.toFixed(2)
                    ).toLocaleString("en-US")}{" "}
                  </h6>
                </>
              ) : (
                <h6 className="datahead">~$0.00 USD</h6>
              )}
              <div className="valuemaindiv">
                <div className="valuemaindiv">
                  <Dropdown align="end">
                    <Dropdown.Toggle id="dropdown-basic">
                      <img
                        src="\assets\dashboardassets\Caret_Down_MD.svg"
                        alt="arrowimg"
                        className="arrowimg"
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <div className="teamsdrops">
                        <div className="innerteamdrop">
                          <p className="teamdroppara">ETH</p>
                          <h6 className="teamdrophead">
                            <span></span>
                            {/* ? (user?.totalEth + user?.totalEthIndirect)?.toFixed(4) */}
                            {getdata ? getdata?.totalEthDirect?.toFixed(4) : 0}{" "}
                          </h6>
                        </div>
                        <div className="innerteamdrop">
                          <p className="teamdroppara">USDT</p>

                          <h6 className="teamdrophead">
                            <span></span>
                            {getdata
                              ? parseFloat(
                                  getdata?.totalUsdtDirect?.toFixed(2)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                        <div className="innerteamdrop">
                          <p className="teamdroppara">DOP</p>

                          <h6 className="teamdrophead">
                            {getdata
                              ? parseFloat(
                                  getdata?.totalDopTokenDirect?.toFixed(2)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                        {project == "DOP" ? (
                          <div className="innerteamdrop">
                            <p className="teamdroppara">TOMI</p>

                            <h6 className="teamdrophead">
                              {getdata
                                ? parseFloat(
                                    getdata?.totalTomiDirect?.toFixed(2)
                                  )?.toLocaleString("en-US")
                                : 0}{" "}
                            </h6>
                          </div>
                        ) : null}
                        <div className="innerteamdrop">
                          <p className="teamdroppara">WBTC</p>

                          <h6 className="teamdrophead">
                            {getdata
                              ? parseFloat(
                                  getdata?.totalWbtcDirect?.toFixed(5)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>{" "}
                        <div className="innerteamdrop">
                          <p className="teamdroppara">USDC</p>

                          <h6 className="teamdrophead">
                            {getdata
                              ? parseFloat(
                                  getdata?.totalUsdcDirect?.toFixed(2)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                        <div className="innerteamdrop">
                          <p className="teamdroppara">PEPE</p>

                          <h6 className="teamdrophead">
                            {getdata
                              ? parseFloat(
                                  (getdata?.totalPepeDirect || 0)?.toFixed(2)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                        <div className="innerteamdrop">
                          <p className="teamdroppara">LINK</p>

                          <h6 className="teamdrophead">
                            {getdata
                              ? parseFloat(
                                  (getdata?.totalLinkDirect || 0)?.toFixed(2)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                        <div className="innerteamdrop">
                          <p className="teamdroppara">UNI</p>

                          <h6 className="teamdrophead">
                            {getdata
                              ? parseFloat(
                                  (getdata?.totalUniDirect || 0)?.toFixed(2)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div className="single-card">
              <img
                src="\assets\leaders\agentearning.svg"
                alt="img"
                className="img-fluid"
              />
              <p>Leader Earnings</p>
              {/* <h6>~50,000 USD</h6> */}
              {usdPrice && getdata ? (
                <h6 className="datahead">
                  ~$
                  {
                    parseFloat(
                      (
                        usdPrice * getdata?.myEarningsEth +
                        getdata?.myEarningsUsdt +
                        tomiPrice * getdata?.myEarningsTomi +
                        dopPrice * getdata?.myEarningsDopToken +
                        btcPrice * getdata?.myEarningsWbtc +
                        getdata?.myEarningsUsdc +
                        (pepePrice * getdata?.myEarningsPepe || 0) +
                        (linkPrice * getdata?.myEarningsLink || 0) +
                        (uniPrice * getdata?.myEarningsUni || 0)
                      )?.toFixed(4)
                    ).toLocaleString("en-US")
                    // getdata?.totalAmountEarned.toFixed(2)
                  }{" "}
                </h6>
              ) : (
                <h6 className="datahead">~$0.00 USD</h6>
              )}
              <div className="valuemaindiv">
                <Dropdown align="end">
                  <Dropdown.Toggle id="dropdown-basic">
                    <img
                      src="\assets\dashboardassets\Caret_Down_MD.svg"
                      alt="arrowimg"
                      className="arrowimg"
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <div className="teamsdrops">
                      <div className="innerteamdrop">
                        <p className="teamdroppara">ETH</p>
                        <h6 className="teamdrophead">
                          {" "}
                          {getdata
                            ? parseFloat(getdata?.myEarningsEth)?.toFixed(5)
                            : ""}{" "}
                        </h6>
                      </div>
                      <div className="innerteamdrop">
                        <p className="teamdroppara">USDT</p>
                        <h6 className="teamdrophead">
                          {getdata
                            ? parseFloat(
                                getdata?.myEarningsUsdt?.toFixed(4)
                              )?.toLocaleString("en-US")
                            : 0}{" "}
                        </h6>
                      </div>
                      <div className="innerteamdrop">
                        <p className="teamdroppara">DOP</p>
                        <h6 className="teamdrophead">
                          {getdata
                            ? parseFloat(
                                getdata?.myEarningsDopToken?.toFixed(5)
                              )?.toLocaleString("en-US")
                            : 0}{" "}
                        </h6>
                      </div>
                      {project == "DOP" ? (
                        <div className="innerteamdrop">
                          <p className="teamdroppara">TOMI</p>
                          <h6 className="teamdrophead">
                            {getdata
                              ? parseFloat(
                                  getdata?.myEarningsTomi?.toFixed(5)
                                )?.toLocaleString("en-US")
                              : 0}{" "}
                          </h6>
                        </div>
                      ) : null}
                      <div className="innerteamdrop">
                        <p className="teamdroppara"> WBTC</p>
                        <h6 className="teamdrophead">
                          {getdata
                            ? parseFloat(getdata?.myEarningsWbtc?.toFixed(6))
                            : 0}{" "}
                        </h6>{" "}
                      </div>
                      <div className="innerteamdrop">
                        <p className="teamdroppara"> USDC</p>
                        <h6 className="teamdrophead">
                          <span></span>
                          {getdata
                            ? parseFloat(
                                getdata?.myEarningsUsdc?.toFixed(4)
                              )?.toLocaleString("en-US")
                            : 0}{" "}
                        </h6>
                      </div>
                      <div className="innerteamdrop">
                        <p className="teamdroppara"> PEPE</p>
                        <h6 className="teamdrophead">
                          <span></span>
                          {getdata
                            ? parseFloat(
                                (getdata?.myEarningsPepe || 0)?.toFixed(4)
                              )?.toLocaleString("en-US")
                            : 0}{" "}
                        </h6>
                      </div>{" "}
                      <div className="innerteamdrop">
                        <p className="teamdroppara"> LINK</p>
                        <h6 className="teamdrophead">
                          <span></span>
                          {getdata
                            ? parseFloat(
                                (getdata?.myEarningsLink || 0)?.toFixed(4)
                              )?.toLocaleString("en-US")
                            : 0}{" "}
                        </h6>
                      </div>{" "}
                      <div className="innerteamdrop">
                        <p className="teamdroppara"> UNI</p>
                        <h6 className="teamdrophead">
                          <span></span>
                          {getdata
                            ? parseFloat(
                                (getdata?.myEarningsUni || 0)?.toFixed(4)
                              )?.toLocaleString("en-US")
                            : 0}{" "}
                        </h6>
                      </div>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="parent-new-card">
            <div className="main-headingg">
              <h6>General Information</h6>
            </div>
            <div className="bottom-cards1">
              <div className="inner-text">
                <p>Access Code</p>
                <h6>{getdata?.accessCode}</h6>
              </div>
              <div className="inner-text">
                <p>Name</p>
                <h6>{getdata?.name}</h6>
              </div>
              <div className="inner-text">
                <p>Direct Sale</p>
                {getdata?.totalDirectSales != 0 ||
                getdata?.totalInDirectSales != 0 ? (
                  <h6>
                    {parseInt(
                      (getdata?.totalDirectSales /
                        (getdata?.totalDirectSales +
                          getdata?.totalInDirectSales)) *
                        100
                    ).toFixed(2)}
                    %
                  </h6>
                ) : (
                  <h6>0%</h6>
                )}
              </div>
              <div className="inner-text">
                <p>Indirect Sale</p>
                {getdata?.totalDirectSales != 0 ||
                getdata?.totalInDirectSales != 0 ? (
                  <h6>
                    {parseInt(
                      (getdata?.totalInDirectSales /
                        (getdata?.totalDirectSales +
                          getdata?.totalInDirectSales)) *
                        100
                    ).toFixed(2)}
                    %
                  </h6>
                ) : (
                  <h6>0%</h6>
                )}
              </div>
              <div className="inner-text">
                <p>Created On</p>
                <h6>{moment(getdata).format("DD/MM/YYYY hh:mm")} UTC</h6>
              </div>
              <div className="inner-text">
                <p>Owner’s Wallet Address</p>
                <h6 className="forflex">{getdata?.walletAddress}</h6>
              </div>
              <div className="inner-text">
                <p>Email address</p>

                <h6>{getdata?.email}</h6>
              </div>
            </div>

            {user?.role == "admin" && (
              <div className="bottom-cards2">
                <h4>Hierarchy</h4>
                {/* <div className="parent-card">
                  <div className="inner-text">
                    <p>God Leader</p>
                    {getdata?.godAgent ? (
                      <>
                        {user?.role == "admin" ? (
                          <>
                            <a
                              //   onClick={() => {
                              //     setUserDel(getdata?.godAgent);
                              //   }}
                              href="#"
                            >
                              <h6 className="style-text">
                                {getdata?.godAgent?.name}
                              </h6>
                            </a>
                          </>
                        ) : (
                          <>
                            <h6>{getdata?.godAgent?.name}</h6>
                          </>
                        )}
                      </>
                    ) : (
                      <h6>None</h6>
                    )}
                  </div>
                  <div className="inner-text">
                    <p>Mega Leader</p>
                    {getdata?.megaAgent ? (
                      <>
                        {user?.role == "admin" ? (
                          <>
                            <a
                              //   onClick={() => {
                              //     setUserDel(getdata?.megaAgent);
                              //   }}
                              href="#"
                            >
                              <h6 className="style-text">
                                {getdata?.megaAgent?.name}
                              </h6>
                            </a>
                          </>
                        ) : (
                          <>
                            <h6>{getdata?.megaAgent?.name}</h6>
                          </>
                        )}
                      </>
                    ) : (
                      <h6>None</h6>
                    )}
                  </div>
                  <div className="inner-text">
                    <p>Super Leader</p>
                    {getdata?.superAgent ? (
                      <>
                        {user?.role == "admin" ? (
                          <>
                            <a
                              //   onClick={() => {
                              //     setUserDel(getData?.superAgent);
                              //   }}
                              href="#"
                            >
                              <h6 className="style-text">
                                {getdata?.superAgent?.name}
                              </h6>
                            </a>
                          </>
                        ) : (
                          <>
                            <h6>{getdata?.superAgent?.name}</h6>
                          </>
                        )}
                      </>
                    ) : (
                      <h6>None</h6>
                    )}
                  </div>
                  <div className="inner-text">
                    <p>Leader</p>
                    {getdata?.agent ? (
                      <>
                        {user?.role == "admin" ? (
                          <>
                            <a
                              //   onClick={() => {
                              //     setUserDel(getdata?.agent);
                              //   }}
                              href="#"
                            >
                              <h6 className="style-text">
                                {getdata?.agent?.name}
                              </h6>
                            </a>
                          </>
                        ) : (
                          <>
                            <h6>{getdata?.agent?.name}</h6>
                          </>
                        )}
                      </>
                    ) : (
                      <h6>None</h6>
                    )}
                  </div>
                </div> */}
                {user?.role === "admin" && (
                  <div className="parent-new-hierarchy">
                    <ul class="clip-path-polygon">
                      <li>
                        <a>
                          <p>God Leader</p>
                          {getdata?.godAgent ? (
                            <>
                              {user?.role == "admin" ? (
                                <>
                                  <span
                                    //   onClick={() => {
                                    //     setUserDel(getdata?.godAgent);
                                    //   }}
                                    href="#"
                                  >
                                    <h6>{getdata?.godAgent?.name}</h6>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <h6>{getdata?.godAgent?.name}</h6>
                                </>
                              )}
                            </>
                          ) : (
                            <h6>None</h6>
                          )}
                        </a>
                      </li>
                      <li>
                        <a className="custom-padding">
                          <p>Mega Leader</p>
                          {getdata?.megaAgent ? (
                            <>
                              {user?.role == "admin" ? (
                                <>
                                  <span
                                    //   onClick={() => {
                                    //     setUserDel(getdata?.megaAgent);
                                    //   }}
                                    href="#"
                                  >
                                    <h6>{getdata?.megaAgent?.name}</h6>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <h6>{getdata?.megaAgent?.name}</h6>
                                </>
                              )}
                            </>
                          ) : (
                            <h6>None</h6>
                          )}
                        </a>
                      </li>
                      <li>
                        <a className="custom-padding">
                          <p>Super Leader</p>
                          {getdata?.superAgent ? (
                            <>
                              {user?.role == "admin" ? (
                                <>
                                  <span
                                    //   onClick={() => {
                                    //     setUserDel(getData?.superAgent);
                                    //   }}
                                    href="#"
                                  >
                                    <h6>{getdata?.superAgent?.name}</h6>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <h6>{getdata?.superAgent?.name}</h6>
                                </>
                              )}
                            </>
                          ) : (
                            <h6>None</h6>
                          )}
                        </a>
                      </li>
                      <li>
                        <a className="custom-padding">
                          <p>Leader</p>
                          {getdata?.agent ? (
                            <>
                              {user?.role == "admin" ? (
                                <>
                                  <span
                                    //   onClick={() => {
                                    //     setUserDel(getdata?.agent);
                                    //   }}
                                    href="#"
                                  >
                                    <h6>{getdata?.agent?.name}</h6>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <h6>{getdata?.agent?.name}</h6>
                                </>
                              )}
                            </>
                          ) : (
                            <h6>None</h6>
                          )}
                        </a>
                      </li>
                      <li class="active">
                        <a className="custom-padding">
                          <p>AMBASSADOR</p>
                          {getdata?.agent ? (
                            <>
                              {user?.role == "admin" ? (
                                <>
                                  <span
                                    //   onClick={() => {
                                    //     setUserDel(getdata?.agent);
                                    //   }}
                                    href="#"
                                  >
                                    <h6>{getdata?.mini_agent?.name}</h6>
                                  </span>
                                </>
                              ) : (
                                <>
                                  <h6>{getdata?.mini_agent?.name}</h6>
                                </>
                              )}
                            </>
                          ) : (
                            <h6>None</h6>
                          )}
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}

            {/* <div className="bottom-cards2">
              <h4>Code Buying Limit</h4>
              <div className="parent-card">
                <div className="inner-text">
                  <a onClick={() => setShow1(true)}>
                    <h6 className="style-text">
                      {getdata?.minimumBuy}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M17.5 18.3335H2.5C2.15833 18.3335 1.875 18.0502 1.875 17.7085C1.875 17.3668 2.15833 17.0835 2.5 17.0835H17.5C17.8417 17.0835 18.125 17.3668 18.125 17.7085C18.125 18.0502 17.8417 18.3335 17.5 18.3335Z"
                          fill="black"
                        />
                        <path
                          d="M15.85 2.90005C14.2333 1.28338 12.65 1.24172 10.9916 2.90005L9.98331 3.90838C9.89998 3.99172 9.86665 4.12505 9.89998 4.24172C10.5333 6.45005 12.3 8.21672 14.5083 8.85005C14.5416 8.85838 14.575 8.86672 14.6083 8.86672C14.7 8.86672 14.7833 8.83338 14.85 8.76672L15.85 7.75838C16.675 6.94172 17.075 6.15005 17.075 5.35005C17.0833 4.52505 16.6833 3.72505 15.85 2.90005Z"
                          fill="black"
                        />
                        <path
                          d="M13.0083 9.60817C12.7666 9.4915 12.5333 9.37484 12.3083 9.2415C12.125 9.13317 11.95 9.0165 11.775 8.8915C11.6333 8.79984 11.4666 8.6665 11.3083 8.53317C11.2916 8.52484 11.2333 8.47484 11.1666 8.40817C10.8916 8.17484 10.5833 7.87484 10.3083 7.5415C10.2833 7.52484 10.2416 7.4665 10.1833 7.3915C10.1 7.2915 9.95831 7.12484 9.83331 6.93317C9.73331 6.80817 9.61665 6.62484 9.50831 6.4415C9.37498 6.2165 9.25831 5.9915 9.14165 5.75817C8.98868 5.43039 8.55847 5.33301 8.3027 5.58879L3.61665 10.2748C3.50831 10.3832 3.40831 10.5915 3.38331 10.7332L2.93331 13.9248C2.84998 14.4915 3.00831 15.0248 3.35831 15.3832C3.65831 15.6748 4.07498 15.8332 4.52498 15.8332C4.62498 15.8332 4.72498 15.8248 4.82498 15.8082L8.02498 15.3582C8.17498 15.3332 8.38331 15.2332 8.48331 15.1248L13.1771 10.4311C13.4278 10.1804 13.3336 9.74912 13.0083 9.60817Z"
                          fill="black"
                        />
                      </svg>
                    </h6>
                  </a>
                </div>
              </div>
            </div> */}
          </div>

          <Tabs
            defaultActiveKey="transactions"
            id="uncontrolled-tab-example"
            className="viewdetail-tabs"
            onSelect={(e) => {
              setTabKey(e);
            }}
          >
            <Tab eventKey="transactions" title="Transactions">
              <div className="main-heading111">
                <div className="rightside-content fornewgap">
                  <div
                    className=" custom-option-field"
                    style={{ width: "85%" }}
                  >
                    <Dropdown className="inputdrop" align="end">
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {searchBy == "name"
                          ? "Name"
                          : searchBy == "accessCode"
                          ? "Access Code"
                          : searchBy == "by"
                          ? "Wallet Address"
                          : ""}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="8"
                          viewBox="0 0 14 8"
                          fill="none"
                        >
                          <path
                            d="M12.9188 0.178711H6.68877H1.07877C0.118772 0.178711 -0.361228 1.33871 0.318772 2.01871L5.49877 7.19871C6.32877 8.02871 7.67877 8.02871 8.50877 7.19871L10.4788 5.22871L13.6888 2.01871C14.3588 1.33871 13.8788 0.178711 12.9188 0.178711Z"
                            fill="black"
                          />
                        </svg>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <a
                          className="dropdown-item drop"
                          onClick={() => setSearchBy("name")}
                        >
                          Name
                        </a>
                        <a
                          className="dropdown-item drop"
                          onClick={() => setSearchBy("accessCode")}
                        >
                          Access Code
                        </a>
                        <a
                          className="dropdown-item drop"
                          onClick={() => setSearchBy("by")}
                        >
                          Wallet Address
                        </a>
                      </Dropdown.Menu>
                    </Dropdown>
                    <input
                      type="search"
                      placeholder="Search..."
                      onChange={(e) => setSearch(e.target.value)}
                      value={search}
                    />

                    <img
                      style={{ cursor: "pointer" }}
                      src="\assets\leaders\search-icon.svg"
                      alt="img"
                      className="img-fluid search-icon"
                    />
                  </div>
                  {getdata?.role !== "mini_agent" &&
                    getdata?.role !== "agent" && (
                      <div class="dropdown">
                        <button
                          class="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Type{" "}
                          <img
                            src="\assets\leaders\arrow-down.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </button>

                        {getdata?.role == "admin" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("god_agent");
                                }}
                              >
                                God
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mega_agent");
                                }}
                              >
                                Mega
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : getdata?.role == "god_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mega_agent");
                                }}
                              >
                                Mega
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : getdata?.role == "mega_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : getdata?.role == "super_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}

                        {/* <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("god_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          God Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("mega_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Mega Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("super_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Super Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Leader
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("mini_agent")}
                          class="dropdown-item"
                          href="#"
                        >
                          Ambassador
                        </a>
                      </li>
                    </ul> */}
                      </div>
                    )}
                </div>
              </div>
              <TransactionsDatatable
                page={page1}
                handlePageChange={handlePageChange}
                pageCount={pageCount}
                count={count}
                transactions={transactions}
                transactionApiCalled={transactionApiCalled}
                setOrderDirection={setOrderDirection}
                setOrderField={setOrderField}
                orderDirection={orderDirection}
                orderField={orderField}
                tok={tok}
              />
            </Tab>
            {getdata?.role !== "mini_agent" && (
              <Tab eventKey="leaders" title="Leaders">
                <div className="new-leaders-detail">
                  <div className="single-card-diff">
                    <p>Total Leaders</p>
                    <h6>
                      {noOfLeaders?.megas +
                        noOfLeaders?.supers +
                        noOfLeaders?.leaders +
                        noOfLeaders?.miniLeaders}
                    </h6>
                  </div>
                  {getdata?.role === "god_agent" && (
                    <div className="multi-parent">
                      <div className="single-card">
                        <p>Mega</p>
                        <h6>{noOfLeaders?.megas ? noOfLeaders.megas : "0"}</h6>
                      </div>
                      <div className="single-card">
                        <p>Super</p>
                        <h6>
                          {noOfLeaders?.supers ? noOfLeaders.supers : "0"}
                        </h6>
                      </div>
                      <div className="single-card">
                        <p>Leader</p>
                        <h6>
                          {noOfLeaders?.leaders ? noOfLeaders.leaders : "0"}
                        </h6>
                      </div>
                      <div className="single-card">
                        <p>AMBASSADOR</p>
                        <h6>
                          {noOfLeaders?.miniLeaders
                            ? noOfLeaders.miniLeaders
                            : "0"}
                        </h6>
                      </div>
                    </div>
                  )}
                  {getdata?.role === "mega_agent" && (
                    <div className="multi-parent">
                      <div className="single-card">
                        <p>Super</p>
                        <h6>
                          {noOfLeaders?.supers ? noOfLeaders.supers : "0"}
                        </h6>
                      </div>
                      <div className="single-card">
                        <p>Leader</p>
                        <h6>
                          {noOfLeaders?.leaders ? noOfLeaders.leaders : "0"}
                        </h6>
                      </div>
                      <div className="single-card">
                        <p>AMBASSADOR</p>
                        <h6>
                          {noOfLeaders?.miniLeaders
                            ? noOfLeaders.miniLeaders
                            : "0"}
                        </h6>
                      </div>
                    </div>
                  )}
                  {getdata?.role === "super_agent" && (
                    <div className="multi-parent">
                      {/* <div className="single-card">
                        <p>Mega</p>
                        <h6>{noOfLeaders?.megas ? noOfLeaders.megas : "0"}</h6>
                      </div> */}
                      {/* <div className="single-card">
                        <p>Super</p>
                        <h6>
                          {noOfLeaders?.supers ? noOfLeaders.supers : "0"}
                        </h6>
                      </div> */}
                      <div className="single-card">
                        <p>Leader</p>
                        <h6>
                          {noOfLeaders?.leaders ? noOfLeaders.leaders : "0"}
                        </h6>
                      </div>
                      <div className="single-card">
                        <p>AMBASSADOR</p>
                        <h6>
                          {noOfLeaders?.miniLeaders
                            ? noOfLeaders.miniLeaders
                            : "0"}
                        </h6>
                      </div>
                    </div>
                  )}
                  {getdata?.role === "agent" && (
                    <div className="multi-parent">
                      <div className="single-card">
                        <p>AMBASSADOR</p>
                        <h6>
                          {noOfLeaders?.miniLeaders
                            ? noOfLeaders.miniLeaders
                            : "0"}
                        </h6>
                      </div>
                    </div>
                  )}
                </div>
                <div className="main-heading2 ">
                  <div className="rightside-content fornewgap">
                    <div className="custom-option-field">
                      <Dropdown className="inputdrop">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                          {searchBy == "name"
                            ? "Name"
                            : searchBy == "accessCodeFilter"
                            ? "Access Code"
                            : searchBy == "walletFilter"
                            ? "Wallet Address"
                            : ""}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                            fill="none"
                          >
                            <path
                              d="M12.9188 0.178711H6.68877H1.07877C0.118772 0.178711 -0.361228 1.33871 0.318772 2.01871L5.49877 7.19871C6.32877 8.02871 7.67877 8.02871 8.50877 7.19871L10.4788 5.22871L13.6888 2.01871C14.3588 1.33871 13.8788 0.178711 12.9188 0.178711Z"
                              fill="black"
                            />
                          </svg>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <a
                            className="dropdown-item drop"
                            onClick={() => setSearchBy("name")}
                          >
                            Name
                          </a>
                          <a
                            className="dropdown-item drop"
                            onClick={() => setSearchBy("accessCodeFilter")}
                          >
                            Access Code
                          </a>
                          <a
                            className="dropdown-item drop"
                            onClick={() => setSearchBy("walletFilter")}
                          >
                            Wallet Address
                          </a>
                        </Dropdown.Menu>
                      </Dropdown>
                      <input
                        type="search"
                        placeholder="Search..."
                        value={searchQuery}
                        onChange={(e) => {
                          setSearchQuery(e.target.value);
                        }}
                      />
                      <img
                        style={{ cursor: "pointer" }}
                        src="\assets\leaders\search-icon.svg"
                        alt="img"
                        className="img-fluid search-icon"
                      />
                    </div>
                    {getdata?.role !== "agent" && (
                      <div class="dropdown">
                        <button
                          class="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Type
                          <img
                            src="\assets\leaders\arrow-down.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </button>
                        {getdata?.role == "admin" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("god_agent");
                                }}
                              >
                                God
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mega_agent");
                                }}
                              >
                                Mega
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : getdata?.role == "god_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mega_agent");
                                }}
                              >
                                Mega
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : getdata?.role == "mega_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("super_agent");
                                }}
                              >
                                Super
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : getdata?.role == "super_agent" ? (
                          <ul class="dropdown-menu">
                            <li>
                              <a
                                onClick={() => setRoleTypeFilter("")}
                                class="dropdown-item"
                                href="#"
                              >
                                All Leaders
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("agent");
                                }}
                              >
                                Leader
                              </a>
                            </li>
                            <li>
                              <a
                                class="dropdown-item"
                                href="#"
                                onClick={() => {
                                  setRoleTypeFilter("mini_agent");
                                }}
                              >
                                Ambassador
                              </a>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}
                        {/* <ul class="dropdown-menu">
                      <li>
                        <a
                          onClick={() => setRoleTypeFilter("")}
                          class="dropdown-item"
                          href="#"
                        >
                          All Leaders
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => {
                            setRoleTypeFilter("god_agent");
                          }}
                        >
                          God
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => {
                            setRoleTypeFilter("mega_agent");
                          }}
                        >
                          Mega
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => {
                            setRoleTypeFilter("super_agent");
                          }}
                        >
                          Super
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => {
                            setRoleTypeFilter("agent");
                          }}
                        >
                          Leader
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="#"
                          onClick={() => {
                            setRoleTypeFilter("mini_agent");
                          }}
                        >
                          Ambassador
                        </a>
                      </li>
                    </ul> */}
                      </div>
                    )}

                    {/* <div class="dropdown">
                      <button
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {leaderFilter ? leaderFilter + " " : "By Name "}
                      <img
                        src="\assets\leaders\arrow-down.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </button>
                    <ul class="dropdown-menu set-custom-scrollbar">
                      <div className="custom-option-field drop-in-search-sticky">
                        <input
                          type="search"
                          placeholder="Search"
                          value={search0}
                          onChange={(e) => GetSearch(e.target.value)}
                        />
                        <img
                          src="\assets\leaders\search-icon.svg"
                          alt="img"
                          className="img-fluid search-icon"
                        />
                      </div>
                      {leaderNames?.length > 0
                        ? leaderNames?.map((item) => {
                            return (
                              <>
                                <li>
                                  <a
                                    class="dropdown-item"
                                    href="#"
                                    onClick={() => {
                                      setLeaderFilter(item?.name);
                                      setSearch0(item?.name);
                                    }}
                                  >
                                    {item?.name}
                                  </a>
                                </li>
                              </>
                            );
                          })
                        : null}
                    </ul>
                    </div> */}
                  </div>
                </div>
                <LeadersDatatable
                  btcPrice={btcPrice}
                  usdPrice={usdPrice}
                  tomiPrice={tomiPrice}
                  currentPage={currentPage}
                  pageCount={pageCountAgent}
                  pages={pages}
                  GetAgents={GetAgents}
                  page={page}
                  count={count}
                  offset={offset}
                  setOffset={setOffset}
                  agents={agents}
                  setAgents={setAgents}
                  setOrderDirection={setOrderDirectionAgents}
                  setOrderField={setOrderFieldAgents}
                  orderDirection={orderDirectionAgents}
                  orderField={orderFieldAgents}
                  handlePageChange={handleAgentsPageChange}
                  pagination={true}
                  leadersLoader={leadersLoader}
                  tok={tok}
                  role={role}
                />
              </Tab>
            )}
          </Tabs>
        </div>
      </section>
      <Footerline />

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Code Buying Limit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="codebuylimit">
            <label>Buying Limit (USD)</label>
            <input type="text" placeholder="0.00" />
          </div>
          <div className="twice-btn">
            <button
              onClick={() => {
                handleClose1();
              }}
              className="btn-cancel"
            >
              Cancel
            </button>
            <button
              className="btn-add"
              onClick={() => {
                handleClose1();
                setShow2(true);
              }}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Code Buying Limit</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="codebuylimit-success">
            <img
              src="\assets\leaders\tick.svg"
              alt="img"
              className="img-fluid"
            />
            <h6>Code buy limit updated successfully</h6>
          </div>
          <div className="twice-btn">
            <button
              className="btn-add"
              style={{ flex: "0 0 100%" }}
              onClick={handleClose2}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="add-mega-modal global-modal-scss"
        show={show3}
        onHide={handleClose3}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Block</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="codebuylimit-success">
            <img
              src="\assets\leaders\block.svg"
              alt="img"
              className="img-fluid"
            />
            <h6>Are you sure you want to block this?</h6>
            <p>This will delete it permanently. You cannot undo this action.</p>
          </div>
          <div className="twice-btn">
            <button
              onClick={() => {
                handleClose3();
              }}
              className="btn-cancel"
            >
              Cancel
            </button>
            <button
              className="btn-add"
              onClick={() => {
                handleClose3();
              }}
              style={{ background: "#FE0000", color: "#fff" }}
            >
              Block
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewDetail;
