import React, { useEffect, useState, useRef, useMemo } from "react";
import Header from "../landing/navbar/Header";
import "./profile.scss";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Footerline from "../landing/footer/Footerline";
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import moment from "moment";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Profile = () => {
  const [activeTab, setActiveTab] = useState("link-1");
  const [profile, setProfile] = useState("link-1");
  const token = localStorage.getItem("accessToken");
  const [copy, setCopy] = useState(false);
  const project = localStorage.getItem("project");
  const textCopiedFun2 = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1000);
  };
  const handleSelect = (eventKey) => {
    setActiveTab(eventKey);
  };

  const AddProfile = async (e) => {
    try {
      const config = {
        method: "get",
        url: `${Api_Url}/users/leaders-profile`,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };
      const response = await axios(config);
      setProfile(response?.data?.data?.userInfo);
      const user = JSON.parse(localStorage?.getItem("user"));
      user.imgUrl = response?.data?.data?.userInfo?.imgUrl;
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem(
        "profileData",
        JSON.stringify(response?.data?.data?.userInfo)
      );
    } catch (error) {
      if (error) {
        console.log(error.response.data.message);
      } else {
      }
      console.error("Error fetching project details:", error);
    }
  };

  useEffect(() => {
    AddProfile();
  }, []);
  const createdAt = moment(profile?.createdAt);

  // Separate date and time
  const formattedDate = createdAt.format("YYYY-MM-DD");
  // const formattedTime = createdAt.format('HH:mm:ss');
  function formatTokenValue(tokenValue) {
    if (tokenValue >= 1000000) {
      return (tokenValue / 1000000).toFixed(2) + "M";
    } else if (tokenValue >= 1000) {
      return (tokenValue / 1000).toFixed(2) + "k";
    } else {
      return tokenValue?.toFixed(2);
    }
  }

  return (
    <>
      <Header />
      <>
        <section className="mainprofile">
          <div className="theme-container">
            <div className="profileparent">
              <div className="left">
                <div className="profilecard">
                  <div className="inner">
                    <div className="uploadedimg">
                      <img
                        src={profile?.imgUrl}
                        alt="img"
                        className="uploadedinnerimg"
                      />
                    </div>

                    <h3>{profile?.name}</h3>
                    <p>
                      {profile?.role == "god_agent"
                        ? "God Leader"
                        : profile?.role == " super_agent"
                        ? " Super Agent"
                        : profile?.role == " mega_agent"
                        ? " Mega Leader"
                        : profile?.role == " agent"
                        ? " Leader"
                        : profile?.role == "mini_agent"
                        ? "Ambassador"
                        : " "}
                    </p>
                    <Link to="/edit">
                      {" "}
                      <button>
                        <img
                          src="\assets\dashboardassets\Note_Edit.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        Edit Profile
                      </button>
                    </Link>
                  </div>
                </div>
                <div className="secondcard">
                  <div className="innerparent">
                    <div className="first">
                      <h3>Projects</h3>
                      <p>1</p>
                    </div>
                    <div className="first">
                      <h3>Total Sale</h3>
                      <p>
                        {profile?.totalAmountInUsd
                          ? formatTokenValue(profile?.totalAmountInUsd)
                          : " 0"}
                      </p>
                    </div>
                    <div className="first">
                      <h3>Earning</h3>
                      <p>
                        $
                        {profile?.totalAmountEarned
                          ? formatTokenValue(profile?.totalAmountEarned)
                          : "0"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right">
                <div className="mainssss">
                  {/* <button onClick={() => window.open(telegramLink, "_blank")}>
                    Join Telegram Group
                  </button> */}
                  {/* Placeholder for the Telegram login button */}

                  <Nav
                    variant="pills"
                    activeKey={activeTab}
                    onSelect={handleSelect}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey="link-1">Profile Info</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                                            <Nav.Link eventKey="link-2">Notification Settings</Nav.Link>
                                        </Nav.Item> */}
                  </Nav>
                </div>

                {activeTab === "link-1" && (
                  <>
                    <div className="tabcard">
                      <div className="parent">
                        <div className="first">
                          <h3>Joined On</h3>
                          <p>{formattedDate}</p>
                        </div>
                        <div className="first">
                          <h3>Wallet Address</h3>
                          <p>
                            {copy ? (
                              <span className="adadasdasdasdasdasdsad">
                                {profile?.walletAddress?.slice(0, 11)}
                                ...
                                {profile?.walletAddress?.slice(
                                  profile?.walletAddress?.length - 3,
                                  profile?.walletAddress?.length
                                )}{" "}
                                <svg
                                  style={{ cursor: "pointer" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  className=""
                                >
                                  <path
                                    d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                    stroke="black"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                {copy && <p className="copy-text">Copied</p>}
                              </span>
                            ) : (
                              <>
                                {profile?.walletAddress && (
                                  <CopyToClipboard
                                    text={profile?.walletAddress}
                                    onCopy={textCopiedFun2}
                                  >
                                    <span className="adadasdasdasdasdasdsad">
                                      {profile.walletAddress?.slice(0, 11)}
                                      ...
                                      {profile?.walletAddress?.slice(
                                        profile?.walletAddress?.length - 3,
                                        profile?.walletAddress?.length
                                      )}{" "}
                                      <svg
                                        style={{ cursor: "pointer" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        className=""
                                      >
                                        <path
                                          d="M13 10.5V13.5C13 16 12 17 9.5 17H6.5C4 17 3 16 3 13.5V10.5C3 8 4 7 6.5 7H9.5C12 7 13 8 13 10.5Z"
                                          stroke="black"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M17 6.5V9.5C17 12 16 13 13.5 13H12.7143V10.7857C12.7143 8.28571 11.7143 7.28571 9.21429 7.28571H7V6.5C7 4 8 3 10.5 3H13.5C16 3 17 4 17 6.5Z"
                                          stroke="black"
                                          stroke-width="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                      {copy && (
                                        <p className="copy-text">Copied</p>
                                      )}
                                    </span>
                                  </CopyToClipboard>
                                )}
                              </>
                            )}
                          </p>
                        </div>
                        <div className="first">
                          <h3>Email address</h3>
                          <p>{profile?.email}</p>
                        </div>
                      </div>
                    </div>
                    <div className="tabcard">
                      {profile?.bio?.length === 0 ? (
                        <>
                          <h2>Bio</h2>
                          <p>Add your bio here ...</p>
                        </>
                      ) : (
                        <>
                          <h2>Bio</h2>
                          <p className="para">{profile?.bio}</p>
                          {/* <p className="para">
                            Sit error voluptas ut error excepturi ea iusto enim
                            sit itaque ipsa non deleniti voluptatem aut fugit
                            culpa. Et reprehenderit omnis sed accusantium nobis
                            et nulla facere quo nihil obcaecati ea officiis
                            placeat.
                          </p> */}
                        </>
                      )}
                    </div>
                  </>
                )}
                {activeTab === "link-2" && (
                  <>
                    <div className="tabcard">
                      <div className="parent foralign">
                        <div className="first">
                          <p>Project Invitations</p>
                        </div>
                        <div className="first">
                          <div class="toggle-switch">
                            <input
                              class="toggle-input"
                              id="toggle"
                              type="checkbox"
                            />
                            <label class="toggle-label" for="toggle"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tabcard">
                      <div className="parent foralign">
                        <div className="first">
                          <p>Project Invitations</p>
                        </div>
                        <div className="first">
                          <div class="toggle-switch">
                            <input
                              class="toggle-input"
                              id="toggle1"
                              type="checkbox"
                            />
                            <label class="toggle-label" for="toggle1"></label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
        <Footerline />
      </>
    </>
  );
};

export default Profile;
