import React, { useEffect } from "react";
import "./staking.scss";
import Datatable from "./DataTable/Datatable";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import Header from "../landing/navbar/Header";
import GetGemsBalance from "../../hooks/dataFetchers/getGemsBalance";
import GetGemsStaked from "../../hooks/dataFetchers/getGemsStakedAmount";
import StakeGems from "../../hooks/dataSenders/stakeGems";
import UnStakeGems from "../../hooks/dataSenders/unstakeGems";
import StakeGemsGas from "../../hooks/dataSenders/stakeGemsGas";
import AllowanceGems from "../../hooks/dataFetchers/allowanceGems";
import GetGemsStakedIndex from "../../hooks/dataFetchers/getGemsStakedIndex";

import ApproveGems from "../../hooks/dataSenders/approveGems";
import { useWeb3React } from "@web3-react/core";
import Loader from "../../hooks/loader";
import { toast } from "react-toastify";
import axios from "axios";
import Environment from "../../utils/Environment";
import { Api_Url } from "../../utils/ApiUrl";
import moment from "moment";

const Staking = ({ usdPrice }) => {
  const user = JSON.parse(localStorage?.getItem("user"));

  const { account } = useWeb3React();
  const { GetGemsBal } = GetGemsBalance();
  const { getGemsStaked } = GetGemsStaked();
  const { stakeGems } = StakeGems();
  const { unstakeGems } = UnStakeGems();
  const { stakeGemsGas } = StakeGemsGas();
  const { allowanceGems } = AllowanceGems();
  const { approveGems } = ApproveGems();
  const { getGemsStakedIndex } = GetGemsStakedIndex();

  const [loader, setLoader] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setWalletError("");
  };
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => {
    setShow1(false);
    setAmount("");
  };
  const handleShow1 = () => setShow1(true);

  const [show2, setShow2] = useState(false);

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);
  const [openWalletModal, setOpenWalletModal] = useState(false);

  const [amount, setAmount] = useState("18000");
  const [amountError, setAmountError] = useState("");
  const [walletError, setWalletError] = useState("");

  const [balance, setBalance] = useState(0);
  const [allowance, setAllowance] = useState(0);

  const [minStakeAmount, setMinStakeAmount] = useState(999999999999999);
  const [stakingGasFees, setStakingGasFees] = useState(0);
  const [stakedAmount, setStakedAmount] = useState(0);

  const [stakeEndTime, setStakeEndTime] = useState([]);
  const [stakingData, setStakingData] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [itemsPerPage] = useState(10); // Define how many items to show per page
  const [orderBy, setOrderBy] = useState("blockTimestamp");
  const [orderDirection, setOrderDirection] = useState("asc");

  useEffect(() => {
    if (user) {
      getGemsBalanceFunc();
      fetchStakingData();
      getGemsStakedAmount();
    }
  }, []);

  useEffect(() => {
    if (
      account &&
      account.toLowerCase() == user?.walletAddress?.toLowerCase()
    ) {
      getAllowance();
    }
  }, [account]);

  const getGemsBalanceFunc = async () => {
    const bal = await GetGemsBal(user?.walletAddress);
    if (bal !== null || bal !== undefined) {
      setBalance(bal);
    }
  };

  const getAllowance = async () => {
    const all = await allowanceGems(account);
    if (all !== null || all !== undefined) {
      setAllowance(all);
    }
  };

  const getGemsStakedAmount = async () => {
    const staked = await getGemsStaked(user?.walletAddress);

    if (staked !== null || staked !== undefined) {
      setStakedAmount(staked);
    }
  };

  const Stake = async () => {
    if (account) {
      if (account.toLowerCase() == user?.walletAddress?.toLowerCase()) {
        if (balance >= amount) {
          if (allowance >= amount) {
            try {
              setLoader(true);
              const gas = await stakeGems(account, amount);

              if (gas) {
                stakeAPI();
                handleClose();
                handleShow1();
                setLoader(false);
                getGemsStakedAmount();
                getGemsBalanceFunc();
                setTimeout(() => {
                  fetchStakingData();
                }, 5000);

                // setStakingGasFees(gas);
                // handleShow();
              }
            } catch (e) {
              setLoader(false);
              console.error(e);
              toast.error(e?.message);
            }
          } else {
            try {
              setLoader(true);

              const approve = await approveGems(account);
              if (approve) {
                getAllowance();
                getGemsBalanceFunc();
                if (balance >= amount) {
                  try {
                    const gas = await stakeGems(account, amount);
                    if (gas) {
                      stakeAPI();
                      handleClose();
                      handleShow1();
                      setLoader(false);
                      getGemsStakedAmount();
                      getGemsBalanceFunc();

                      setTimeout(() => {
                        fetchStakingData();
                      }, 5000);

                      // setStakingGasFees(gas);
                      // handleShow();
                    }
                  } catch (e) {
                    setLoader(false);
                    console.error(e);
                    toast.error(e?.message);
                  }
                } else {
                  // handleShow2();
                  setLoader(false);

                  setWalletError("Not Enough GEMS Balance");
                }
              }
            } catch (e) {
              setLoader(false);
              console.error(e);
              toast.error(e?.message);
            }
          }
        } else {
          // handleShow2();
          setWalletError("Not Enough GEMS Balance");
        }
      } else {
        setWalletError("Incorrect Wallet!");
      }
    } else {
      // setWalletError("Connect Wallet!");
      handleClose();
      setOpenWalletModal(true);
    }
  };

  const unstake = async () => {
    if (account.toLowerCase() == user?.walletAddress?.toLowerCase()) {
      setLoader(true);
      try {
        const index = await getGemsStakedIndex(account);
        if (index) {
          try {
            const gas = await unstakeGems(account, parseInt(index) - 1);
            if (gas) {
              // handleShow1();
              setLoader(false);
              getGemsStakedAmount();
              setTimeout(() => {
                fetchStakingData();
              }, 5000);

              // getGemsBalanceFunc();
              // setStakingGasFees(gas);
              // handleShow();
            }
          } catch (e) {
            setLoader(false);
            console.error(e);
            toast.error(e?.message);
          }
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      setAmountError("Incorrect Wallet!");
    }
  };

  const calculateStakeGas = async () => {
    if (account.toLowerCase() == user?.walletAddress?.toLowerCase()) {
      setLoader(true);
      if (allowance > amount) {
        try {
          const gas = await stakeGemsGas(account, amount);
          if (gas) {
            setStakingGasFees(gas);
            handleShow();
            setLoader(false);
          }
        } catch (e) {
          setLoader(false);
          console.error(e);
          toast.error(e?.message);
        }
      } else {
        try {
          const approve = await approveGems(account);
          if (approve) {
            getAllowance();

            const gas = await stakeGemsGas(account, amount);

            if (gas) {
              setStakingGasFees(gas);
              handleShow();
              setLoader(false);
            }
          }
        } catch (e) {
          setLoader(false);
          console.error(e);
          toast.error(e?.message);
        }
      }
    } else {
      setAmountError("Incorrect Wallet!");
    }
  };

  const stakeAPI = async () => {
    let tok = localStorage.getItem("accessToken");
    var config = {
      method: "patch",
      url: `${Api_Url}/users/update-leader-plan`,
      headers: {
        authorization: `Bearer ` + tok,
      },
    };
    await axios(config)
      .then(function (response) {})
      .catch(function (error) {
        console.error(error?.message);
      });
  };

  const fetchStakingData = async () => {
    try {
      const response = await axios.post(
        Environment?.GemsStakingGraph,
        {
          query: `
            query MyQuery($staker: String, $isClaimed: Boolean,) {
              stakeds(where: {staker: $staker}, isClaimed: $isClaimed,) {
              
                blockTimestamp
                stakeEndTime
                isClaimed
              }
            }`,
          variables: {
            staker: user?.walletAddress,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const resData = response.data.data.stakeds;
      setStakingData(resData);
      const currentTime = moment();
      const endTimeStampInSeconds = parseInt(
        resData[resData?.length - 1]?.stakeEndTime
      );
      const endTimeStamp = moment(endTimeStampInSeconds * 1000); // Convert to milliseconds
      if (!endTimeStamp.isValid()) {
        setStakeEndTime(currentTime);
      } else {
        setStakeEndTime(endTimeStamp);
      }
    } catch (error) {
      console.error("Error fetching staking data: ", error);
    }
  };

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (value === "" || (Number(value) <= balance && Number(value) >= 0)) {
      setAmount(value);
      setAmountError("");
    } else {
      setAmountError("Amount exceeds balance");
    }
  };

  const handleSorting = (sortBy) => {
    setOrderBy(sortBy);
    setOrderDirection(
      orderBy === sortBy && orderDirection === "asc" ? "desc" : "asc"
    );
  };

  const handlePagination = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      {loader && <Loader />}

      <Header
        openWalletModal={openWalletModal}
        setOpenWalletModal={setOpenWalletModal}
      />
      <section className="main-staking">
        <div className="upperbanner">
          <div className="theme-container">
            <h6 className="staketoppara">gems premium</h6>
            <h4 className="stakemainhead">
              Enjoy 100% of your rewards with Gems Premium!
            </h4>
            <p>
              Lock GEMS token for a year and enjoy full potential of GEMS
              platform
            </p>
          </div>
        </div>
        <div className="lowerbanner">
          <div className="theme-container">
            {/* <div className="lowerinnerbanner">
              <div className="lowerleft">
                <span className="lowerleftshade"></span>
                <h6 className="totalpara">TOTAL GEMS Staked</h6>
                <div className="totalgemsmain">
                  <img
                    src="\assets\gemstoken.svg"
                    alt="gemslogoimg"
                    className="gemslogoimg"
                  />
                  <p className="totalgemspara">
                    {stakedAmount?.toLocaleString()} GEMS
                  </p>
                </div>
                <div className="stakebalance">
                  <p className="stakepara">Stake GEMS</p>
                  <p className="balancepara">
                    <span>Balance:</span>
                    <span>{balance?.toLocaleString()} GEMS</span>
                  </p>
                </div>
                <input
                  type="number"
                  placeholder="Enter amount of GEMS to stake"
                  className="amountinput"
                  value={amount}
                  max={balance}
                  onChange={handleAmountChange}
                />
                <div className="mb-4">
                  <p className="text-danger">{amountError}</p>
                </div>
                <div className="stakingperiodmain">
                  <p className="stakingperiodpara">Staking Period</p>
                  <p className="stakingyearpara">1 Year </p>
                </div>
                <button
                  // onClick={handleShow}
                  className="approvebtn"
                  // disabled={amount < 18000}
                  onClick={() => {
                    if (!account) {
                      // setAmountError("Connect Wallet First!");
                      toast.info("Connect Your Wallet");
                    } else if (amount < 18000) {
                      setAmountError("Cannot stake less than 18000 GEMS!");
                    } else if (balance > amount) {
                      // Stake();
                      calculateStakeGas();
                    } else {
                      setAmountError("Insufficient GEMS Balance!");
                    }
                  }}
                >
                  {allowance > amount ? "Stake" : "Approve"}
                </button>
              </div>
              <div className="lowerright">
                <p className="lowerrightpara">Stake History</p>
                <Datatable
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalItems={totalItems}
                  orderBy={orderBy}
                  setOrderBy={setOrderBy}
                  orderDirection={orderDirection}
                  setOrderDirection={setOrderDirection}
                  setCurrentPage={setCurrentPage}
                  stakingData={stakingData}
                  handlePagination={handlePagination}
                  handleSorting={handleSorting}
                  unstake={unstake}
                />
              </div>
            </div> */}
            <div className="newboxes-twice">
              <div className="single-box">
                <div className="upper-content">
                  <h6>basic</h6>
                  <h4>
                    <span>0</span> GEMS
                  </h4>
                  <p>Your starting point in your GEMS leader journey!</p>
                </div>
                <div className="bottom-content">
                  <div className="inner-single">
                    <div className="text">
                      <img
                        src="\assets\premium\reward.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <p className="">Rewards</p>
                    </div>
                    {/* <img
                      src="\assets\premium\check.svg"
                      alt="img"
                      className="img-fluid"
                    /> */}
                    <h6>50%</h6>
                  </div>
                  <div className="inner-single">
                    <div className="text">
                      <img
                        src="\assets\premium\addunlimited.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <p>Can participate in presale</p>
                    </div>
                    <img
                      src="\assets\premium\check.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="inner-single">
                    <div className="text">
                      <img
                        src="\assets\premium\earn100.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <p>Presale Discount</p>
                    </div>
                    <img
                      src="\assets\premium\cross.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="inner-single">
                    <div className="text">
                      <img
                        src="\assets\premium\privaterounddiscount.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <p>Private Sale Round Discount</p>
                    </div>
                    <img
                      src="\assets\premium\cross.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                  <div className="inner-single">
                    <div className="text">
                      <img
                        src="\assets\premium\addleader.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <p>Add Leaders</p>
                    </div>
                    {/* <img
                      src="\assets\premium\cross.svg"
                      alt="img"
                      className="img-fluid"
                    /> */}
                    <h6>Upto 20</h6>
                  </div>
                  <div className="inner-single">
                    <div className="text">
                      <img
                        src="\assets\premium\priority.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <p>Customer Support</p>
                    </div>
                    <img
                      src="\assets\premium\cross.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </div>
                </div>
                {stakedAmount >= 18000 ? null : (
                  <a className="btn-plan">CURRENT PLAN</a>
                )}
              </div>
              <div className="main-bottom-ahead">
                <div className="single-box activee">
                  <span className="gradient-activee"></span>
                  <div className="upper-content">
                    <div className="d-flex justify-content-between align-item-center">
                      <h6 style={{ color: "#E2B854" }}>PREMIUM</h6>
                      {stakingData?.length > 0 &&
                      moment().isAfter(stakeEndTime) ? (
                        <p
                          className="cdhbdybydbcy"
                          style={{ color: "#FF4A4A" }}
                        >
                          Plan expired on{" "}
                          {moment(stakeEndTime)?.format("DD/MM/YY")}
                        </p>
                      ) : null}
                    </div>
                    <h4>
                      <span>18000</span> GEMS
                    </h4>
                    <p>Enjoy full benefits of the GEMS platform!</p>
                  </div>
                  <div className="bottom-content">
                    <div className="inner-single">
                      <div className="text">
                        <img
                          src="\assets\premium\reward.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <p>Rewards</p>
                      </div>
                      {/* <img
                        src="\assets\premium\check.svg"
                        alt="img"
                        className="img-fluid"
                      /> */}
                      <h6>100%</h6>
                    </div>
                    <div className="inner-single">
                      <div className="text">
                        <img
                          src="\assets\premium\canparticipate.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <p>Can participate in presale</p>
                      </div>
                      <img
                        src="\assets\premium\check.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                    <div className="inner-single">
                      <div className="text">
                        <img
                          src="\assets\premium\earn100.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <p>Presale Discount</p>
                      </div>
                      {/* <img
                        src="\assets\premium\check.svg"
                        alt="img"
                        className="img-fluid"
                      /> */}
                      <h6>20%</h6>
                    </div>
                    <div className="inner-single">
                      <div className="text">
                        <img
                          src="\assets\premium\privaterounddiscount.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <p>Private Sale Round Discount</p>
                      </div>
                      {/* <img
                      src="\assets\premium\cross.svg"
                      alt="img"
                      className="img-fluid"
                    /> */}
                      <h6>2%</h6>
                    </div>
                    <div className="inner-single">
                      <div className="text">
                        <img
                          src="\assets\premium\addleader.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <p>Add Leaders</p>
                      </div>
                      {/* <img
                      src="\assets\premium\cross.svg"
                      alt="img"
                      className="img-fluid"
                    /> */}
                      <h6>Unlimited</h6>
                    </div>
                    <div className="inner-single">
                      <div className="text">
                        <img
                          src="\assets\premium\priority.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <p>Customer Support</p>
                      </div>
                      <img
                        src="\assets\premium\check.svg"
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </div>

                  {stakedAmount < 18000 ? (
                    <a
                      className="btn-plan activee"
                      onClick={() => {
                        handleShow();
                      }}
                    >
                      Upgrade
                    </a>
                  ) : stakingData?.length > 0 &&
                    moment().isBefore(stakeEndTime) &&
                    stakedAmount >= 18000 ? (
                    <a className="btn-plan" style={{ flexDirection: "column" }}>
                      <h6>CURRENT PLAN</h6>
                      <p>
                        expires on {moment(stakeEndTime)?.format("DD/MM/YY")}
                      </p>
                    </a>
                  ) : stakingData?.length > 0 &&
                    moment().isAfter(stakeEndTime) ? (
                    <div className="twice-btns">
                      <a
                        className="withdraw-gems"
                        onClick={() => {
                          unstake();
                        }}
                      >
                        Withdraw Gems
                      </a>
                      <a
                        className="upgrade-gems"
                        onClick={() => {
                          handleShow();
                        }}
                      >
                        upgrade Again
                      </a>
                    </div>
                  ) : null}

                  {/* <a href="#" className="btn-plan" style={{flexDirection: "column"}}>
                    <h6>CURRENT PLAN</h6>
                    <p>expires on 6/07/24</p>
                  </a> */}
                  {/* <div className="twice-btns">
                    <a className="withdraw-gems" onClick={()=>{unstake();}}>
                      Withdraw Gems
                    </a>
                    <a href="#" className="upgrade-gems">
                      upgrade Again
                    </a>
                  </div> */}
                </div>
                {/* <p className="bottom-para">
                  *In order to enter the first round you need to be a premium
                  leader and hold total amount of at least 30k gems{" "}
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        className="stakingmodal premiumstake-modal"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upgrade to Premium</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="stakingmodal">
            <div className="inner-content">
              <img
                src="\assets\premium\modalbg.png"
                alt="img"
                className="img-fluid modalbg-stake"
              />
              <img
                src="\assets\premium-icon1.svg"
                alt="img"
                className="img-fluid"
              />
              <h5>
                Are you sure you want to <br />
                upgrade to GEMS PREMUM?
              </h5>
              <p>This will lock your 18,000 GEMS for 1 Year</p>
            </div>
            <div className="d-flex justify-content-center mb-4">
              <p className="text-danger ">{walletError}</p>
            </div>
            <div className="stakingbtns">
              <button
                onClick={() => {
                  handleClose();
                }}
                className="cancelbtn"
              >
                Cancel
              </button>
              <button
                className="confirmbtn"
                onClick={() => {
                  Stake();
                }}
              >
                Yes UPGRADE
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="stakingmodal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upgrade to Premium</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addedmodal">
            <img
              src="\assets\tickimg.svg"
              alt="addedimg"
              className="addedimg"
            />
            <h6 className="successhead">upgraded to premium</h6>
            <p className="succesaspara">
              Enjoy 100% of your rewards and all <br /> the premium features!
            </p>
            <button
              className="greybtn w-100"
              onClick={() => {
                handleClose1();
              }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        className="stakingmodal"
        show={show2}
        onHide={handleClose2}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upgrade to Premium</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addedmodal">
            <img
              src="\assets\premium\cross.svg"
              alt="addedimg"
              className="addedimg"
            />
            <h6 className="successhead">Insufficient GEMS Balance</h6>
            <p className="succesaspara">
              You do not have enough GEMS in <br /> your wallet!
            </p>
            <button
              className="greybtn w-100"
              onClick={() => {
                handleClose2();
              }}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Staking;
