import React from "react";
import "./forgotpassword.scss";
import { Link } from "react-router-dom";
import Header from "../landing/navbar/Header";
import Footerline from "../landing/footer/Footerline";

const Emailsent = () => {
  return (
    <>
      <Header />
      <section className="forgotmain">
        <div className="forgotinner">
          <img
            src="\assets\email-marketing.svg"
            alt="emailimg"
            className="emailimg"
          />
          <h5 className="forgothead">Email has been Sent!</h5>
          <p className="forgotpara widthpara">
            Please check your inbox and click on the received link to reset your
            password.
          </p>
          <Link to="/login" className="greybtn w-100">
            Proceed To Login
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
            >
              <path
                d="M6.86318 2.3761V3.82075H8.30785V2.3761H6.86318ZM8.30785 3.82075V5.26471H9.7518V3.82075H8.30785ZM9.7518 5.26471V6.70936H0.831635V8.15122H9.7518V9.59516H11.1965V8.15122H13V6.70936H11.1965V5.26471H9.7518ZM9.7518 9.59516H8.30785V11.0398H9.7518V9.59516ZM8.30785 11.0398H6.86318V12.4838H8.30785V11.0398Z"
                fill="black"
              />
            </svg>
          </Link>
          <Link to="/forgotpassword" className="resendpara">
            Resend Email
          </Link>
        </div>
      </section>
      <Footerline />
    </>
  );
};

export default Emailsent;
