import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import {
  getclaimscontract,
  getclaimsnewcontract,
  getGemsClaimsContract,
  getIncentivClaimsContract,
  getLEIAClaimsContract,
  getOlympusClaimsContract,
} from "../../utils/contractHelpers";
import { getClaimNewLatest } from "../../utils/contractHelpers";
const AllowClaimHook = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.Claims;
  const contract = getclaimscontract(tokenAddress, web3);
  const tokenAddressNew = environment.ClaimsNew;
  const contractNew = getclaimsnewcontract(tokenAddressNew, web3);
  const tokenAddressTomi = environment.claimNewLatest;
  const contractNewLatest = getClaimNewLatest(tokenAddressTomi, web3);
  const gemsAddress = environment.GemsClaim;
  const incentivAddress = environment.IncentivClaim;
  const LEIAAddress = environment.LEIAClaim;
  const OlympusAddress = environment.OlympusClaim;

  const gemsContract = getGemsClaimsContract(gemsAddress, web3);
  const incentivContract = getIncentivClaimsContract(incentivAddress, web3);
  const LEIAContract = getLEIAClaimsContract(LEIAAddress, web3);
  const OlympusContract = getOlympusClaimsContract(OlympusAddress, web3);

  const project = localStorage.getItem("project");
  const allowClaimHook = useCallback(
    async (account, round, tokensAddressArray) => {
      if (project == "INCENTIV") {
        try {
          const gas = await incentivContract.methods
            .claim(round, tokensAddressArray)
            .estimateGas({ from: account });

          const details = await incentivContract.methods
            .claim(round, tokensAddressArray)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "LEIA") {
        try {
          const gas = await LEIAContract.methods
            .claim(round, tokensAddressArray)
            .estimateGas({ from: account });
          const details = await LEIAContract.methods
            .claim(round, tokensAddressArray)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "OLYMPUS") {
        try {
          const gas = await OlympusContract.methods
            .claim(round, tokensAddressArray)
            .estimateGas({ from: account });
          const details = await OlympusContract.methods
            .claim(round, tokensAddressArray)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else if (project == "GEMS") {
        try {
          const gas = await gemsContract.methods
            .claim(round, tokensAddressArray)
            .estimateGas({ from: account });
          const details = await gemsContract.methods
            .claim(round, tokensAddressArray)
            .send({
              from: account,
              gas,
            });
          return details;
        } catch (error) {
          throw error;
        }
      } else {
        if (parseInt(round) < 3) {
          try {
            const gas = await contract.methods
              .claim(round)
              .estimateGas({ from: account });
            const details = await contract.methods.claim(round).send({
              from: account,
              gas,
            });
            return details;
          } catch (error) {
            throw error;
          }
        } else if (parseInt(round) > 6) {
          try {
            const gas = await contractNewLatest.methods
              .claim(round, tokensAddressArray)
              .estimateGas({ from: account });
            const details = await contractNewLatest.methods
              .claim(round, tokensAddressArray)
              .send({
                from: account,
                gas,
              });
            return details;
          } catch (error) {
            throw error;
          }
        } else {
          try {
            const gas = await contractNew.methods
              .claim(round)
              .estimateGas({ from: account });
            const details = await contractNew.methods.claim(round).send({
              from: account,
              gas,
            });
            return details;
          } catch (error) {
            throw error;
          }
        }
      }
    },
    [contract]
  );
  return { allowClaimHook: allowClaimHook };
};
export default AllowClaimHook;
