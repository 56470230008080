import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import Web3 from "web3";
import environment from "../../utils/Environment";
import { getGemsStakingContract } from "../../utils/contractHelpers";

const StakeGemsGas = () => {
  const web3 = useWeb3();
  const tokenAddress = environment.GemsStaking;
  const contract = getGemsStakingContract(tokenAddress, web3);

  const chainId = process.env.REACT_APP_NODE_1;
  const web3Gas = new Web3(new Web3.providers.HttpProvider(chainId));

  const stakeGemsGas = useCallback(
    async (account, amount) => {
      // let nd = 1000000000000000000000;
      // nd = web3.utils.toWei(nd.toString(), "ether");
      const gasP = await web3Gas.eth.getGasPrice();
      const gasPWei = parseInt(gasP) / 10 ** 9;
      try {
        let nd = web3.utils.toWei(amount.toString(), "ether");

        const gas = await contract.methods
          .stake(nd.toString())
          .estimateGas({ from: account });

        // const details = await contract.methods.stake(amount).send({
        //   from: account,
        //   gas,
        // });
        const gf = parseInt(gas.toString()) / 10 ** 9;
        return gf * gasPWei;
      } catch (error) {
        throw error;
      }
    },
    [contract]
  );
  return { stakeGemsGas: stakeGemsGas };
};
export default StakeGemsGas;
